import React from 'react'
import { Link } from 'react-router-dom';
import servicesData from '../assets/servicesData';

function Footer() {
    const date = new Date();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

  return (
    <div className='p-5 text-white font-montserrat' style={{backgroundColor: "#323232"}}>

        <div className='block lg:flex justify-around gap-12 w-5/6 mx-auto mt-10 mb-10'>

            <div className='w-full lg:w-1/3 mb-5'>

                <img src={require('../assets/images/navi-logo.png')} className='w-44 mb-6' alt="Logo" />
                <div className='text-sm w-3/4 mb-5'>Stay up to date on the latest features and releases by joining our newsletter</div>
                <Link to={"/contact_us"} onClick={e => {
                    scrollToTop();
                }} className='bg-primary-100 rounded-lg px-4 py-1 text-sm'>Contact Us</Link>
                <div className='text-sm mt-5 w-3/4'>By subscribing, you agree to our Privacy Policy & consent to receive updates from our company</div>

            </div>

            <div className='w-full lg:w-1/3 mb-5 lg:mt-10'>
                <div className='font-bold mb-3'>Services</div>
                {
                    servicesData.map(service => 
                        <div className='mb-2 text-sm'><Link to={`/services#${service.id}`}>{service.title}</Link></div>
                    )
                }
            </div>

            <div className='w-full lg:w-1/3 mb-5  mt-10'>
                <div className='mb-5'>
                    {/* <Link to={"/"} className="hover:bg-orange-600 bg-primary-100 p-1 px-2 rounded-lg text-white text-sm">Book Service Appointment</Link> */}
                </div>
                <div className='mb-2 text-sm'><Link>Terms and Conditions</Link></div>
                <div className='mb-2 text-sm'><Link>Privacy Policy</Link></div>
                <div className='mb-2 text-sm'><Link>Return Policy</Link></div>
            </div>

        </div>

        <div className='text-center font-montserrat text-white text-sm'>
            Copyright {date.getFullYear()} Navi All Rights Reserved
        </div>
      
    </div>
  )
}

export default Footer
