import React from 'react'

function SalesBranches() {
  return (
    <div className='p-10 px-5 lg:px-10 gap-52 font-montserrat mt-16' style={{ backgroundColor: "#f3f3f3"}}>

        <div className='block lg:flex border border-gray-200 rounded-2xl w-full lg:w-3/4 mx-auto'>

        <div className='rounded-2xl lg:rounded-none bg-white w-full lg:w-1/2 p-4 lg:p-8 text-sm'>
        <div className='capitalize text-sm lg:text-lg font-bold text-center'>Find us at the following Sales and Service Branches:</div>
            <div className='w-full mx-auto bg-zinc-100 p-4 rounded-lg mt-5'>
                <div className='font-bold tracking-wide uppercase text-primary-100'>Nairobi Branch - Head Office</div>
                <div className='mt-2'>Contact Persons:</div>
                <div className='ml-5 mt-2 text-xs'>
                    <div className='font-bold'>AFTER-SALES DIRECTOR:</div>
                    <div>ACE WANG - +254 791 971 317</div>
                </div>

                <div className='ml-5 mt-2 text-xs'>
                    <div className='font-bold'>AFTER-SALES MANAGER</div> 
                    <div>NACLAD ONYANGO - +254 718 539 393</div>
                </div>
            </div>

            <div className='w-full mx-auto bg-zinc-100 p-4 rounded-lg mt-5'>
                <div className='font-bold tracking-wide uppercase text-primary-100'>Mombasa Branch</div>
                <div className='mt-2'>Port Reiz</div>
                <div className='mt-2'>Contact Person:</div>
                <div className='ml-5 mt-2'>
                    <div className='capitalize text-xs'>Stephen nzioka - +254 727 128 620</div>
                </div>
            </div>

            <div className='w-full mx-auto bg-zinc-100 p-4 rounded-lg mt-5'>
                <div className='font-bold tracking-wide uppercase text-primary-100'>Eldoret Branch</div>
                <div className='mt-2'>Contact Person:</div>
                <div className='ml-5 mt-2'>
                    <div className='text-xs'>Azim Abubar - +254 729 569 401</div>
                </div>
            </div>

            <div className='w-full mx-auto bg-zinc-100 p-4 rounded-lg mt-5'>
                <div className='font-bold tracking-wide uppercase text-primary-100'>Kisumu Branch</div>
                <div className='mt-2'>Contact Person:</div>
                <div className='ml-5 mt-2'>
                    <div className='text-xs'>Martin Mutwota - +254 722 647 398</div>
                </div>
            </div>
                
        </div>

        <div className="w-full lg:w-1/2 lg:flex items-center justify-center bg-white">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7315335163644!2d36.89073998458833!3d-1.3373439321859684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f13fff52dcf11%3A0x10ecfd9cc5de4819!2sRUNDA%20HEAVY%20INDUSTRY%20LTD%20NAIROBI%20HEAD%20OFFICE!5e0!3m2!1sen!2ske!4v1729845196729!5m2!1sen!2ske" className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1XGKVxvE1N1IKbtOg37wQTaX2sTkQHCA&ehbc=2E312F&noprof=1&z=6" className='w-full h-96 lg:h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        </div>
      
    </div>
  )
}

export default SalesBranches
