import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUs() {

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [subject, setSubject] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();

        if(firstName == null || lastName == null || email == null || phoneNumber == null || subject == null || message == null){
            toast("All Fields Must be filled",{
              type:"error",
              autoClose: 500
            })
            setLoading(false);
            return;
          }

        let  htmlTemplate =  `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Order Notification</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #fff;
                    margin: 0;
                    padding: 20px;
                    color: #333;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    border: 1px solid #333;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                }
                .header {
                    background-color: #333;
                    color: #fff;
                    padding: 10px;
                    text-align: center;
                }
                .content {
                    padding: 20px;
                }
                .footer {
                    background-color: #333; /* Black background */
                    color: #fff;
                    text-align: center;
                    padding: 10px;
                }
                .label {
                    font-weight: bold;
                    color: #333; 
                }
                span {
                    margin-right: 5px;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="header">
                    <h1>Customer Inquiry from Ecommerce Site</h1>
                </div>
                <div class="content">
                    <p><span class="label">Full Name:</span>${firstName} ${lastName}</p>
                    <p><span class="label">Email:</span> ${email}</p>
                    <p><span class="label">Phone Number:</span>${phoneNumber}</p>
                    <p><span class="label">Subject:</span>${subject}</p>
                    <p><span class="label"><b>message:</b></p>
                    </p>${message}</p>
                </div>
                <div class="footer">
                    <p>Reach out to the customer!</p>
                </div>
            </div>
        </body>
        </html>
        `

        let sentData = { 
            subject: "New Customer Inquiry From Navi Ecommerce",
            htmlTemplate
        };

        let larkMessage =
            `🚨 ${sentData.subject.toUpperCase()}\n\nCustomer Name: ${firstName} ${lastName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nSubject: ${subject}\nMessage: ${message}`;

        sentData = { ...sentData, larkMessage}

        fetch(`${process.env.REACT_APP_API_URL}/send_email`,{
            method: 'POST',
            headers: {
              "Content-Type":"application/json"
            },
            body: JSON.stringify(sentData)
          })
          .then(()=>{
            toast("Message Received! We will communicate back as soon as possible",{
              type:"success",
              autoClose: 1500
            });
            setLoading(false);
            setFirstName(null);
            setLastName(null);
            setPhoneNumber(null);
            setEmail(null);
            setSubject(null);
            setMessage(null);
          })
    }
  return (
    <div className='p-10 px-5 lg:px-24 gap-52 font-montserrat mt-16' style={{ backgroundColor: "#f3f3f3"}}>
        <ToastContainer />

        <div className='flex border border-gray-200 rounded-2xl w-full lg:w-3/4 mx-auto'>

        <div className='rounded-2xl lg:rounded-none bg-zinc-200 w-full lg:w-1/2 p-4 lg:p-8 text-sm'>

            <div className='underline underline-offset-8 font-extrabold text-2xl tracking-wide'>Get In Touch</div>
            <div className='mt-3'>We will get back to you as soon as possible!</div>
                <form className='mt-10 text-sm'>

                    <div className='block lg:flex gap-5'>
                        <div className='w-full lg:w-1/2'>
                            <input type="text" placeholder='First Name' className='p-2 py-3 rounded-lg w-full mb-5 lg:mb-0' onChange={e => setFirstName(e.target.value)} required />
                        </div>
                        <div className='w-full lg:w-1/2'>
                            <input type="text" placeholder='Last Name' className='p-2 py-3 rounded-lg w-full' onChange={e => setLastName(e.target.value)} required />
                        </div>
                    </div>

                    <div>
                        <input type='email' placeholder='johndoe@gmail.com' className='p-2 py-3 rounded-lg w-full mt-5' onChange={e => setEmail(e.target.value)} required />
                    </div>

                    <div>
                        <input type='text' placeholder='Phone Number' className='p-2 py-3 rounded-lg w-full mt-5' onChange={e => setPhoneNumber(e.target.value)} required />
                    </div>

                    <div>
                        <input type='text' placeholder='Subject' className='p-2 py-3 rounded-lg w-full mt-5' onChange={e => setSubject(e.target.value)} required />
                    </div>

                    <div>
                        <textarea onChange={e => setMessage(e.target.value)} rows={10} type='text' placeholder='Message' className='p-4 py-3 rounded-lg w-full mt-5' required></textarea>
                    </div>

                    <div className="mt-5 text-center font-mono bg-blue-200 p-2 rounded-lg">
                        * Thank you! We will get back to you as soon as you submit this contact form.
                    </div>

                    <div className='flex justify-center'>
                        {
                            loading ? <button className='bg-gray-300 p-2 rounded-lg mt-5 text-black w-full lg:w-1/2' onClick={e => e.preventDefault()}>
                                Loading ...
                            </button> 
                            :
                            <button onClick={e => handleSubmit(e)} type="submit" className='bg-primary-100 hover:bg-orange-600 p-2 rounded-lg mt-5 text-white w-full lg:w-1/2'>SEND NOW</button>
                        }
                        
                    </div>
                </form>
            </div>

            <div className="w-0 lg:w-1/2 hidden lg:flex items-center justify-center bg-white">
                <div>
                    <img src={require('../assets/images/navi-logo.png')} className="object-contain" />
                    <div className='text-center font-bold mt-2'>Navi Motors Limited</div>
                </div>
            </div>

        </div>
      
    </div>
  )
}

export default ContactUs
