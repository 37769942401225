import React from 'react';
import { Link } from 'react-router-dom';

const ZCRubber = () => {
  return (
    <div className="bg-gray-200 py-16 px-10 lg:px-28 shadow-md font-montserrat text-sm">
      <div className='text-black text-base lg:text-xl font-bold mb-5'>
        We have Partnered with ZC Rubber; a leading tire manufacturer from China
      </div>

      {/* Logo image positioned conditionally based on screen size */}
      <div className="flex flex-col lg:flex-row items-start mb-4">
        <img 
          src={require('../assets/images/logo1.png')} 
          alt="ZC Rubber Logo" 
          className="mb-4 lg:mb-0 lg:w-1/3 lg:mr-4 rounded-lg" 
        />
        <div>
            <h2 className="text-xl font-bold mb-3">About ZC Rubber</h2>
            <p className="mb-4 text-justify">
            ZC Rubber is a leading tire manufacturer committed to producing a wide range of high-quality tires for various vehicles. They specialize in truck tires, passenger car tires, industrial tires, off-the-road tires, agricultural tires, bicycle tires, motorcycle tires, and ATV tires. ZC Rubber partners with reputable brands to ensure that customers receive durable and reliable products tailored to their needs.
        </p>
        <p className="mb-4">
            With a strong focus on innovation and quality, ZC Rubber has established a global presence, supplying tires to customers across six continents and in over 160 countries. Their dedication to excellence and customer satisfaction makes them a trusted partner for tire solutions worldwide.
        </p>
        <div className="mt-6">
            <Link to="https://www.zc-rubber.com" target="_blank" className="bg-primary-100 text-white px-4 py-2 rounded-lg hover:bg-primary-100">
            More Info
            </Link>
        </div>
        </div>
      </div>

      
      {/* <div className="flex flex-col lg:flex-row gap-4">
        <img 
          src={require('../assets/images/Night View Rendering.jpg')} 
          alt="ZC Rubber Tires" 
          className="w-full lg:w-1/2 rounded-lg" 
        />
      </div> */}
    </div>
  );
};

export default ZCRubber;
