import React from 'react';

function CarouselBanner() {
  return (
    <div className='mt-5 w-full py-10' style={{height:'550px'}}>
      <img src={require('../assets/images/poster (1).png')} className='w-full' style={{height:'500px'}}/>
    </div>
    // <div className='flex flex-col lg:flex-row items-center py-10 px-10 lg:px-28 gap-10 lg:gap-52 h-1/3 mt-5 font-montserrat' style={{ backgroundColor: "#f7f7f7"}}>

    // <div className='block lg:hidden w-full lg:w-3/4'>
    //     <img src={require('../assets/images/spare-parts.png')} className='w-auto' alt="Spare Parts" />
    // </div>

    //   {/* Text Section */}
    //   <div className='text-center lg:text-left'>
    //     <div className='font-bold capitalize text-3xl lg:text-4xl'>
    //       Find Parts That Fit Your Vehicle
    //     </div>
    //     <div className='capitalize mt-5 lg:mt-5 font-bold tracking-wide' style={{color:"#808080"}}>
    //       We have products ranging from Semi Trailers, Tyre's, Fuel Sensors, GPS Trackers and many more...
    //     </div>
    //   </div>

    //   {/* Image Section */}
    //   <div className='hidden lg:block w-full lg:w-3/4'>
    //     <img src={require('../assets/images/spare-parts.png')} className='w-auto' alt="Spare Parts" />
    //   </div>

    // </div>
  );
}

export default CarouselBanner;
