import React, { useEffect, useState } from 'react'
import servicesData from '../assets/servicesData'
import { useLocation } from 'react-router-dom';
import BookAppointmentModal from './BookAppointmentModal';
import GetAQuoteModal from './GetAQuoteModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Services() {
    const location = useLocation();

    useEffect(() => {
        // Check if there's a hash in the URL
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location]);

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isQuoteModalOpen, setQuoteModalOpen] = useState(false);

    const openQuoteModal = () => setQuoteModalOpen(true);
    const closeQuoteModal = () => setQuoteModalOpen(false);

    const [data, setData] = useState(null);  
  

  return (
    <div className='p-10 px-5 lg:px-24 gap-52 font-montserrat mt-16' style={{ backgroundColor: "#f3f3f3"}}>

        <ToastContainer />

        <div className='font-bold mb-8 text-2xl' style={{color: '#767676'}}>Services We Offer:</div>

    <div className=''>
    {
        servicesData.map((service, index) => (
            <div 
                key={index}
                className={`flex flex-col-reverse lg:flex-row ${index % 2 === 0 ? 'lg:flex-row-reverse' : ''} p-4 py-10 lg:py-20 ${index % 2 === 0 ? 'bg-white' : 'bg-[#f7f7f7]'}`}
                id={service.id}
            >
                <div className='w-full lg:w-5/6 p-4'>
                    <div className='font-bold'>{service.title}</div>
                    <div className='mt-5 text-sm lg:text-base'>{service.description}</div>
                    {
                        service.services.map(item => (
                            <li className='ml-5 mb-1 mt-1 list-outside text-sm lg:text-base'>{item}</li>
                        ))
                    }
                    <div className='mt-5 text-sm lg:text-base'>{service.summary}</div>

                    <div className='flex gap-5'>
                        <button onClick={() => {
                            setData(service)
                            openQuoteModal()
                        }} className='mt-5  border border-primary-100 rounded-lg text-primary-100 hover:text-white hover:bg-orange-600 px-1 lg:px-2 py-1 text-xs lg:text-sm'>Get A Quote</button>
                        { service.title == "Tyre Maintenance" ? <button onClick={() => {
                            setData(service)
                            openModal()
                        }} className='mt-5 bg-primary-100 hover:bg-orange-800 text-white px-1 lg:px-2 py-1 rounded-lg text-xs lg:text-sm'>Book Service Appointment</button> 
                        : null }
                    </div>
                </div>
                <div className='flex justify-center w-full mb-0 lg:mb-4 h-44 lg:h-96 mt-5 lg:mt-0'>
                    <img src={service.image} className='object-contain' alt={service.title} />
                </div>
            </div>
        ))
    }

    <BookAppointmentModal isOpen={isModalOpen} onClose={closeModal} data={data} />
    <GetAQuoteModal isOpen={isQuoteModalOpen} onClose={closeQuoteModal} data={data} />

    </div>
    </div>
  )
}

export default Services
