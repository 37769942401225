import React, { useState } from 'react';
import useCart from '../context/CartContext';

const TrailerModal = ({ isOpen, onClose, data }) => {
    const { addToCart } = useCart();
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = (input) => {
        addToCart({ ...input, quantity: Number(quantity) });
    };

  if (!isOpen) return null; 

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={onClose}></div>

      {/* Modal content */}
      <div className="relative bg-white w-5/6 lg:w-1/2 p-6 rounded-lg shadow-lg z-20">
        <div className="flex justify-end">
          <button className="font-bold text-red-600" onClick={onClose}>X</button>
        </div>
        <div className="mt-4">
            <div className='flex justify-center w-full mb-0 lg:mb-4 h-20 lg:h-56 gap-5'>
                {
                    data.images.map( (image, index) => <img src={image} className='object-contain w-72' alt={`Trailer${index}`} /> )
                }
            </div>

            <div className='w-full p-4'>  

            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-xs lg:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {/* <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-2 lg:px-6 py-3">
                                Key
                            </th>
                            <th scope="col" class="px-2 lg:px-6 py-3">
                                Value
                            </th>
                        </tr>
                    </thead> */}
                    <tbody>
                        
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" class="p-1 text-gray-900 whitespace-nowrap dark:text-white">
                                Trailer Name
                            </th>
                            <td class="p-1 font-bold text-sm">
                                {data.name}
                            </td>
                        </tr>
                        {

                        data.description.map( desc => (
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-xs">
                            <th scope="row" class="p-1 text-gray-900">
                                {desc.key}
                            </th>
                            <td class="p-1 font-bold text">
                                {desc.value}
                            </td>
                        </tr> ))
                        }

                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" class="p-1 text-gray-900 whitespace-nowrap dark:text-white">
                                Price
                            </th>
                            <td class="p-1 font-bold text-sm">
                                <div>{ (data.price - 1000).toLocaleString('en-US', { minimumFractionDigits: 2 }) } USD each  - 1 - 10 trailers</div>
                                <div>{(data.price - 2000).toLocaleString('en-US', { minimumFractionDigits: 2 })} USD each - 11 - 20 trailers</div>
                                <div>{(data.price - 3000).toLocaleString('en-US', { minimumFractionDigits: 2 })} USD each - 21+ trailers</div>
                            </td>
                        </tr>
                    
                    </tbody>
                </table>
            </div>

            <div className='flex justify-center mt-5'>
                <button onClick={e => {
                    e.preventDefault();
                    handleAddToCart(data);
                }} className='bg-primary-100 hover:bg-orange-600 text-white rounded-2xl px-2 py-1 w-3/4 lg:w-1/4'>Add To Cart</button>
            </div>

            </div>
        </div>
      </div>
    </div>
  );
};

export default TrailerModal;