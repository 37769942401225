import { useEffect, useState } from "react";
import CarouselBanner from "./components/CarouselBanner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Partners from "./components/Partners";
import SalesBanner from "./components/SalesBanner";
import TopProducts from "./components/TopProducts";
import TopServices from "./components/TopServices";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Route, Routes } from "react-router-dom";
import Products from "./components/Products";
import ProductsBanner from "./components/ProductsBanner";
import ProductSalesBanner from "./components/ProductSalesBanner";
import ServicesBanner from "./components/ServicesBanner";
import Services from "./components/Services";
import ServicesSalesBanner from "./components/ServicesSalesBanner";
import ContactUsBanner from "./components/ContactUsBanner";
import ContactUs from "./components/ContactUs";
import SalesBranchesBanner from "./components/SalesBranchesBanner";
import SalesBranches from "./components/SalesBranches";
import ZCRubber from "./components/ZcRubber";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./components/Login";
import Register from "./components/Register";
import Cart from "./components/Cart";
import PlaceOrder from "./components/PlaceOrder";
import SuccesfulOrder from "./components/SuccesfulOrder";


function App() {

    const [showArrow, setShowArrow] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 100) {
          setShowArrow(true);
        } else {
          setShowArrow(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

  return (
    <div className="App min-h-screen">
        <ToastContainer />
        <Header />
        <Routes>
            <Route path="/" element={
              <>
                <CarouselBanner />
                <TopProducts />
                <TopServices />
                <ZCRubber />
                <SalesBanner />
                <Partners />
              </>
            } />
            <Route path="/products" element={
              <>
                {/* <ProductsBanner /> */}
                <Products />
                <ProductSalesBanner />
              </>
              }  />

            <Route path="/services" element={
              <>
                {/* <ServicesBanner /> */}
                <Services />
                <ServicesSalesBanner />
              </>
              }  />

            <Route path="/login" element={
              <>
                <Login />
              </>
              }  />

            <Route path="/order" element={
              <>
                <PlaceOrder />
              </>
              }  />

            <Route path="/cart" element={
              <>
                <Cart />
              </>
              }  />

            <Route path="/register" element={
              <>
                <Register />
              </>
              }  />

            <Route path="/contact_us" element={
                <>
                  {/* <ContactUsBanner /> */}
                  <ContactUs />
                </>
                }  />
            
            <Route path="/order_succesfull" element={
              <>
                <SuccesfulOrder />
              </>
            } />

            <Route path="/sales_branches" element={
                <>
                  {/* <SalesBranchesBanner /> */}
                  <SalesBranches />
                </>
                }  />
          </Routes>
        
        <Footer />

        {showArrow && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-10 right-8 bg-primary-100 text-white rounded-full p-3 shadow-lg"
        >
          <ArrowUpwardIcon />
        </button>
      )}
    </div>
  );
}

export default App;
