import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookAppointmentModal = ({ isOpen, onClose, data }) => {
  

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [branch, setBranch] = useState("Nairobi Branch");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getMinDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Set to tomorrow
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // Set limits for the time input
  const minTime = '08:30'; // 8:30 AM
  const maxTime = '17:30'; // 5:30 PM

  let  htmlTemplate =  `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Service Appointment Booked</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #fff;
                    margin: 0;
                    padding: 20px;
                    color: #333;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    border: 1px solid #333;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                }
                .header {
                    background-color: #333;
                    color: #fff;
                    padding: 10px;
                    text-align: center;
                }
                .content {
                    padding: 20px;
                }
                .footer {
                    background-color: #333; /* Black background */
                    color: #fff;
                    text-align: center;
                    padding: 10px;
                }
                .label {
                    font-weight: bold;
                    color: #333; 
                }
                span {
                    margin-right: 5px;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="header">
                    <h1>Service Appointment Booked By Customer</h1>
                </div>
                <div class="content">
                    <p><span class="label">Customer Name:</span>${firstName} ${lastName}</p>
                    <p><span class="label">Email:</span> ${email}</p>
                    <p><span class="label">Phone Number:</span>${phoneNumber}</p>

                    <p><span class="label">Date:</span>${date}</p>
                    <p><span class="label">Time:</span>${time}</p>
                    <p><span class="label">Branch:</span>${branch}</p>
                </div>
                <div class="footer">
                    <p>Reach out to the customer!</p>
                </div>
            </div>
        </body>
        </html>
        `

  let sentData = { 
      subject: "New Service Appointment Booked",
      htmlTemplate
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  if (!isOpen) return null;


  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if(firstName == null || lastName == null || email == null || phoneNumber == null || date == null || time == null){
      toast("All Fields Must be filled",{
        type:"error",
        autoClose: 500
      })
      setLoading(false);
      return;
    }

    if (!date || !time) {
      setError('Please select both date and time.');
      setLoading(false);
      return; // Prevent submission if fields are empty
    }

    let title = data.title;

    let larkMessage =
        `🚨 ${sentData.subject.toUpperCase()}\nCustomer Name: ${firstName} ${lastName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nService: ${title}\nBranch: ${branch}\nDate: ${date}\nTime: ${time}`;

    sentData = { ...sentData, larkMessage }

    fetch(`${process.env.REACT_APP_API_URL}/send_email`,{
      method: 'POST',
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(sentData)
    })
    .then(()=>{
      toast("Service Appointment Has Been Booked",{
        type:"success",
        autoClose: 1500
      });
      setLoading(false);
      setFirstName(null);
      setLastName(null);
      setPhoneNumber(null);
      setEmail(null);
      setDate(null);
      setTime(null);
      setBranch('Nairobi Branch');

      setTimeout(()=>{
        onClose();
      },2000)
    })
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20">

      <ToastContainer />
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={onClose}></div>

      {/* Modal content */}
      <div className="relative bg-white w-5/6 lg:w-1/2 p-6 rounded-lg shadow-lg z-20 text-sm">
        <form>
            <div className='mb-5 text-center text-2xl uppercase font-montserrat font-bold tracking-wide'>Book {data.title} Appointment</div>
            
            <div className='flex w-full gap-4'>
                <div className='w-1/2'>
                    <input type="text" onChange={e => {
                        setFirstName(e.target.value)
                    }} className='border border-gray-300 p-2 w-full rounded-lg' placeholder="First Name" required />
                </div>
                <div className='w-1/2'>
                    <input type="text" onChange={e => {
                        setLastName(e.target.value)
                    }} className='border border-gray-300 p-2 w-full rounded-lg' placeholder="First Name" required />
                </div>
            </div>
            <div>
                <input type="email" onChange={e => {
                    setEmail(e.target.value);
                }} className='border border-gray-300 p-2 w-full rounded-lg mt-5' placeholder='johndoe@gmail.com' required />
            </div>
            <div>
                <input type="text" 
                onChange={e => {
                    setPhoneNumber(e.target.value);
                }} className='border border-gray-300 p-2 w-full rounded-lg mt-5' placeholder='Phone Number' required />
            </div>

            <div className='mt-5'> 
                    <div>
                        <div className='mt-2 font-bold mb-1 ml-1'>Select branch:</div>
                        <select className='p-2 rounded-lg border border-gray-300 w-full' onChange={e => setBranch(e.target.value)} required>
                        <option value={"Nairobi Branch"}>Nairobi Branch</option>
                        <option value={"Kisumu Branch"}>Kisumu Branch</option>
                        <option value={"Eldoret Branch"}>Eldoret Branch</option>
                        <option value={"Mombasa Branch"}>Mombasa Branch</option>
                        </select>
                    </div>                
            </div>
            <div className='block'>
                <div className='font-bold mt-5'>Select Date:</div>
                <input
                className='border border-gray-300 p-2 rounded-lg w-full'
                type="date"
                id="date"
                value={date}
                onChange={handleDateChange}
                min={getMinDate()}
                required
                />
            </div>
            <div>
                <div className='font-bold mt-5'>Select Time:</div>
                <input
                className='border border-gray-300 p-2 rounded-lg w-full'
                type="time"
                id="time"
                value={time}
                onChange={handleTimeChange}
                min={minTime} // Set minimum time to 8:30 AM
                max={maxTime} // Set maximum time to 5:30 PM
                required
                />
            </div>

            <div className="mt-5 text-center font-mono bg-blue-200 p-2 rounded-lg">
                * Thank you! We will get back to you as soon as you submit this appointment form.
            </div>
            

            <div className='flex justify-center gap-3'>
                <button onClick={(e) => {
                  e.preventDefault();                  
                  onClose();
                  }} className='border border-primary-100 text-primary-100 hover:bg-orange-600 hover:text-white py-2 px-4 rounded-lg w-1/2 lg:w-1/4 mt-5 text-xs lg:text-sm'>Cancel</button>

                  {
                    loading ? 
                    <button onClick={e => {
                      e.preventDefault();
                  }} className='bg-gray-400 py-2 px-4 rounded-lg text-black w-1/2 lg:w-1/4 mt-5 text-xs lg:text-sm'>Loading ...</button>
                    :
                    <input onClick={e => {
                      handleSubmit(e);
                  }} type="submit" value="Book Appointment" className='bg-primary-100 hover:bg-orange-600 py-2 px-4 rounded-lg text-white w-1/2 lg:w-1/4 mt-5 text-xs lg:text-sm'/>
                  }
                
            </div>
            
        </form>
      </div>
    </div>
  );
};

export default BookAppointmentModal;