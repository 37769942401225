const tyreData = [
    {
        _id: "tyre001",
        type: "tyre",
        image: require('../assets/images/AS668-无logo.png'),
        name: "GoodRide AS668",
        vehicle: "Truck & Buses",
        purpose: "Long Haul",
        price: 38500.00,
        description: [
            "Premium five-rib tread provides outstanding handling for steer application",
            "Decoupling grooves prevent uneven tread wear and ensure long tread life for long distance travel",
            "Wide and deep tread guarantee longer mileage with affordable cost and remarkable value"
        ]
    },
    {
        _id: "tyre004",
        type: "tyre",
        image: require('../assets/images/CM913A-朝阳-1.png'),
        name: "GoodRide CM913A",
        vehicle: "Truck & Buses",
        purpose: "Long Haul",
        price: 39000.00,
        description: [
            "Drive axle tyre applicable for mud and sand terrain with short on high-way use",
            "Wide tread and open shoulder blocks help enhance traction and braking on rough roads with mud or gravel",
            "Special tread compounds resist chipping, chunking and tearing"
        ]
    },
    {
        _id: "tyre003",
        type: "tyre",
        image: require('../assets/images/MD777.png'),
        name: "GoodRide MD777",
        vehicle: "Truck & Buses",
        purpose: "Mixed Service",
        price: 42000.00,
        description: [
            "Premium tyre designed for drive position applicable for rough road surface",
            "Aggressive direction tread with robust tread blocks providing extra traction for on/off road condition",
            "Special rubber compound ensuring more resistance to cuts and tearing"
        ]
    },
    // {
    //     image: require('../assets/images/cr931.png'),
    //     name: "GoodRide CR931",
    //     vehicle: "Truck & Buses",
    //     purpose: "Lang Haul",
    //     price: 82500.00,
    //     description: [
    //         "Wide base multi-rib trailer tyres with durable compound to deliver excellent value",
    //         "Strong casing structure offers multiple retreads and lower total cost",
    //         "Improved tread design promises outstanding stability with optimized footprint and better tyre life"
    //     ]
    // },
    // {
    //     image: require('../assets/images/cb905.png'),
    //     name: "GoodRide CB905",
    //     vehicle: "Truck & Buses",
    //     purpose: "Mining",
    //     price: 93500.00,
    //     description: [
    //         "Aggressive blocks provides outstanding traction on rough roads",
    //         "Suitable for all season and all position application on mud and sand terrain with some short on high-way use",
    //         "Cool-running compound enhances toughness casing"
    //     ]
    // },
    // {
    //     image: require('../assets/images/EZ330.png'),
    //     name: "GoodRide EZ330",
    //     vehicle: "Truck & Buses",
    //     purpose: "Mining",
    //     price: 24000.00,
    //     description: [
    //         "Special tread compounds resist chipping and chunking in rugged off-road conditions",
    //         "Robust tread block help promote traction and floatation capacity on rough roads",
    //         "Applicable for low speed mining service"
    //     ]
    // },
    // {
    //     image: require('../assets/images/CM998.png'),
    //     name: "GoodRide CM998",
    //     vehicle: "Truck & Buses",
    //     purpose: "Mixed Service",
    //     price: 47600.00,
    //     description: [
    //         "Aggressive block design provides outstanding traction on rough roads",
    //         "Wide tread and special tread compounds resist chipping, chunking, and tearing",
    //         "Durable tyre casing for heavy loads",
    //         "Applicable for low speed use. Maximum speed shall be limited to 70 km/h in winter and 50 km/h in summer"
    //     ]
    // },
    // {
    //     image: require('../assets/images/EZ310.png'),
    //     name: "GoodRide EZ310",
    //     vehicle: "Truck & Buses",
    //     purpose: "Mining",
    //     price: 56300.00,
    //     description: [
    //         "Brand new flagship tyre for mining application with Enhanced tyre life and durability based on PSCT",
    //         "Special mining compound helps resist chipping, chunking and tearing for a better mileage",
    //         "Super robust block provides outstanding traction on off road surface",
    //         "Centre tie bar design minimizes longitudinal elongation and deformation",
    //         "Stone ejector platforms prevent tread damage from stone drilling"
    //     ]
    // },
    // {
    //     image: require('../assets/images/EZ870.png'),
    //     name: "GoodRide EZ870",
    //     vehicle: "Truck & Buses",
    //     purpose: "Mining",
    //     price: 87000.00,
    //     description: [
    //         "Brand new flagship tyre for mining application with Enhanced tyre life and durability based on PSCT",
    //         "Special mining compound helps resist chipping, chunking and tearing for a better mileage",
    //         "Super robust block provides outstanding traction on off road surface",
    //         "Centre tie bar design minimizes longitudinal elongation and deformation",
    //         "Stone ejector platforms prevent tread damage from stone drilling"
    //     ]
    // }
];

export default tyreData;
