import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import tyreData from '../assets/tyreData';
import semiTrailerData from '../assets/semiTrailersData';
import TrailersCard from './TrailersCard';

function Products() { 
    const itemsPerPage = 4;
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState('All Categories');
    const [sortedData, setSortedData] = useState([]);
    const [filteredTyreData, setFilteredTyreData] = useState(tyreData);
    const [filteredTrailerData, setFilteredTrailerData] = useState(semiTrailerData);

    useEffect(() => {
        let numOfPages = Math.ceil(filteredTyreData.length / itemsPerPage);
        setNumberOfPages(numOfPages);
        handlePageChange(1);
    }, [filteredTyreData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        let endItemIndex = (itemsPerPage * pageNumber) - 1;
        let startItemIndex = endItemIndex - (itemsPerPage - 1);
        let newData = filteredTyreData.slice(startItemIndex, (endItemIndex + 1));
        setSortedData(newData);
    }

    const handleCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);

        if (category === 'All Categories') {
            setFilteredTyreData(tyreData);
            setFilteredTrailerData(semiTrailerData);
        } else if (category === 'Tyres') {
            setFilteredTyreData(tyreData);
            setFilteredTrailerData([]);
        } else if (category === 'Semi Trailers') {
            setFilteredTyreData([]);
            setFilteredTrailerData(semiTrailerData);
        }
    }

    // Create pagination
    const pages = [];
    for (let i = 1; i <= numberOfPages; i++) {
        pages.push(i);
    }

    return (
        <div className='p-10 px-5 lg:px-24 gap-52 font-montserrat mt-16' style={{ backgroundColor: "#f3f3f3"}}>
            <div className='bg-white rounded-lg p-4 flex justify-between items-center text-sm lg:text-base'>
                <div className='font-bold' style={{color: '#767676'}}>Sort By Category</div>
                <select className='p-2 rounded-lg' style={{backgroundColor: "#f3f3f3"}} value={selectedCategory} onChange={handleCategoryChange}>
                    <option>All Categories</option>
                    <option>Tyres</option>
                    <option>Semi Trailers</option>
                </select>
            </div>

            <div className='flex justify-center gap-2 lg:gap-28 mt-10 mb-10 flex-wrap'>
                {
                    sortedData.map(item => <ProductCard key={item.id} data={item} />)
                }
                {
                    filteredTrailerData.slice(0, 4).map(item => <TrailersCard key={item.id} data={item} />)
                }
            </div>

            <div className='flex gap-4 text-sm'>
                {
                    pages.map(page => (
                        <button 
                            key={page}
                            className={`px-2 py-1 rounded-md text-white w-8 text-center ${currentPage === page ? 'bg-primary-100' : 'bg-gray-500'}`} 
                            onClick={e => {
                                e.preventDefault();
                                handlePageChange(page);
                            }}
                        >
                            {page}
                        </button>
                    ))
                }
                <button 
                    className='px-2 py-1 rounded-md text-white text-center bg-gray-500' 
                    onClick={e => {
                        e.preventDefault();
                        if (currentPage < numberOfPages) {
                            handlePageChange(currentPage + 1);
                        }
                    }}
                >
                    {"Next >"}
                </button>
            </div>
        </div>
    );
}

export default Products;
