import React from 'react'
import { Link } from 'react-router-dom'

function ServicesSalesBanner() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className='my-5 p-5 font-montserrat' style={{backgroundColor: "#f7f7f7"}}>
      <div className='mx-auto rounded-lg w-full lg:w-3/4 bg-gradient-to-r from-zinc-500 via-zinc-700 to-black flex p-5 my-5 lg:px-10 lg:py-10 gap-5 items-center'>
        <div className='w-1/3 lg:w-1/4'>
          <img 
            src={require('../assets/images/logo1.png')} 
            className='w-96 lg:w-72 object-contain' 
            alt='Tyre' 
          />
        </div>

        <div className='w-2/3 lg:w-3/4 my-auto'>
          <div className='text-white text-lg lg:text-2xl font-semibold'>
            We have Partnered with ZC Rubber: Offering Top-Quality Tyre Products and Services
          </div>
          <div className='text-white mt-2 lg:mt-4 text-xs lg:text-base mb-3 lg:mb-5'>
              We are proud to partner with ZC Rubber, a leading tyre manufacturer in China, to bring you premium tyres and top-notch tyre services. From high-quality tyre products to expert maintenance solutions, we ensure your vehicles perform at their best on every journey.
          </div>
          <Link to={"/contact_us"} onClick={()=>{
            scrollToTop()
          }} className='bg-primary-100 hover:bg-orange-600 text-white rounded-3xl px-3 py-1  text-xs lg:text-base'>
            Get In Touch!
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ServicesSalesBanner
