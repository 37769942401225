import React, { useEffect, useState } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ProductCard from './ProductCard';
import { Link } from 'react-router-dom';
import tyreData from '../assets/tyreData';
import semiTrailerData from '../assets/semiTrailersData';
import TrailersCard from './TrailersCard';
import { ToastContainer } from 'react-toastify';
import StarIcon from '@mui/icons-material/Star';

function TopProducts() {
    const [tyreSalesData, setTyresSalesData] = useState(0);
    const [semiTrailerSalesData, setSemiTrailerSalesData] = useState(0);

    const [tyresSold, setTyresSold] = useState(0);
    const [semisSold, setSemisSold] = useState(0);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/get-counts`)
        .then((res)=> res.json())
        .then( data => {
            setTyresSalesData(data.tyres);
            setSemiTrailerSalesData(data.semitrailers);
        })
        .catch(err => {
            console.log(err);
        })
    },[])

    // Count up animation effect for tyres sold
    useEffect(() => {
        let tyreCount = 0;
        const tyreInterval = setInterval(() => {
            if (tyreCount < tyreSalesData) {
                tyreCount++;
                setTyresSold(tyreCount);
            } else {
                clearInterval(tyreInterval);
            }
        }, 1); // Adjust speed as needed

        let semiCount = 0;
        const semiInterval = setInterval(() => {
            if (semiCount < semiTrailerSalesData) {
                semiCount++;
                setSemisSold(semiCount);
            } else {
                clearInterval(semiInterval);
            }
        }, 1); // Adjust speed as needed

        return () => {
            clearInterval(tyreInterval);
            clearInterval(semiInterval);
        };
    }, [tyreSalesData, semiTrailerSalesData]);

    return (
        <div className='p-10 px-5 lg:px-24 gap-52 font-montserrat' style={{ backgroundColor: "#f3f3f3" }}>
            <ToastContainer />

            <div className='flex justify-between'>
                <div className='text-xl font-bold'>Top Products</div>
            </div>

            <div className='mt-10 text-zinc-500 font-bold tracking-wide'>Tyres</div>

            <div className='flex justify-between gap-2 lg:gap-2 mt-5 mb-10 flex-wrap'>
                {
                    tyreData.slice(0, 4).map(item => <ProductCard key={item.id} data={item} />) // Ensure a unique key for each item
                }
                <div className="bg-white shadow-lg p-2 w-full lg:w-1/5 rounded-lg border border-gray-200  pb-5">
                    <div className="mb-5 text-center font-montserrat capitalize bg-gray-50 p-2">
                        <div className="text-3xl font-bold mt-10">{tyresSold}</div> {/* Large count up */}
                        <div className='text-lg'>tyres sold</div>
                        <div className='text-gray-400 font-bold text-sm mb-5'>Order Your's Today</div>
                    </div>
                    <div className="flex items-center mb-3">
                        <span className="text-lg font-semibold text-blue-600 text-center w-full uppercase">Tyre Discount Deals</span>
                    </div>
                    <div className="space-y-2 text-xs font-medium text-gray-700 leading-6 text-center">
                        <div className="items-center">
                            <StarIcon sx={{ fontSize: "16px", color: "#ffcf40" }} />
                            Order
                            <span className="bg-blue-50 text-blue-700 font-semibold px-2 py-1 rounded-full mx-2">1-20 tyres</span>
                            <span>Get 500 KES Discount on each Tyre</span>
                        </div>
                        <div className="items-center">
                            <StarIcon sx={{ fontSize: "16px", color: "#ffcf40" }} />
                            Order
                            <span className="bg-blue-100 text-blue-700 font-semibold px-2 py-1 rounded-full mx-2">21-40 tyres</span>
                            <span>Get 1000 KES Discount on each Tyre</span>
                        </div>
                        <div className="items-center">
                            <StarIcon sx={{ fontSize: "16px", color: "#ffcf40" }} />
                            Order
                            <span className="bg-blue-200 text-blue-700 font-semibold px-2 py-1 rounded-full mx-2">41+ tyres</span>
                            <span>Get 2000 KES Discount on each Tyre</span>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className='mt-10 text-zinc-500 font-bold tracking-wide'>Semi Trailers</div>
            <div className='flex justify-between gap-2 lg:gap-2 mt-5 mb-10 flex-wrap'>
                {
                    semiTrailerData.slice(0, 4).map(item => <TrailersCard key={item.id} data={item} />) // Ensure a unique key for each item
                }
                <div className="bg-white shadow-lg p-4 w-full lg:w-1/5 rounded-lg border border-gray-200 pb-15">
                    <div className="mb-5 text-center font-montserrat capitalize bg-gray-50 p-2">
                        <div className="text-3xl font-bold mt-10">{semisSold}</div> {/* Large count up */}
                        <div className='text-lg'>semi-trailers sold</div>
                        <div className='text-gray-400 font-bold text-sm mb-10'>Order Your's Today</div>
                    </div>
                    <div className="flex items-center mb-3">
                        <span className="text-lg font-semibold text-blue-600 text-center w-full uppercase">Semi Trailer Deals</span>
                    </div>
                    <div className="space-y-2 text-xs font-medium text-gray-700 leading-6 text-center">
                        <div className="items-center">
                            <StarIcon sx={{ fontSize: "16px", color: "#ffcf40" }} />
                            Order
                            <span className="bg-blue-50 text-blue-700 font-semibold px-2 py-1 rounded-full mx-2">1-10 Semitrailers</span>
                            <span>Get 1000 USD Discount on each Semi trailer</span>
                        </div>
                        <div className="items-center">
                            <StarIcon sx={{ fontSize: "16px", color: "#ffcf40" }} />
                            Order
                            <span className="bg-blue-100 text-blue-700 font-semibold px-2 py-1 rounded-full mx-2">11-20 Semitrailers</span>
                            <span>Get 2000 KES Discount on each Semi trailer</span>
                        </div>
                        <div className="items-center">
                            <StarIcon sx={{ fontSize: "16px", color: "#ffcf40" }} />
                            Order
                            <span className="bg-blue-200 text-blue-700 font-semibold px-2 py-1 rounded-full mx-2">21+ Semitrailers</span>
                            <span>Get 3000 USD Discount on each Semi trailer</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TopProducts;
