import React, { useState } from 'react'
import ProductModal from './ProductModal';
import OrderModal from './OrderModal';
import TrailerModal from './TrailerModal';
import useCart from '../context/CartContext';

function TrailersCard({ data }) {

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isOrdersModalOpen, setOrdersModalOpen] = useState(false);

    const openOrdersModal = () => setOrdersModalOpen(true);
    const closeOrdersModal = () => setOrdersModalOpen(false);
    
    const { addToCart } = useCart();
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = (input) => {
        addToCart({ ...input, quantity: Number(quantity) });
    };
  return (
    <div className='bg-white p-2 w-full lg:w-1/4 rounded-lg'>
        <div className='flex justify-center w-full mb-0 lg:mb-4 h-36'>
            <img src={data.images[0]} className='object-contain' alt="Tyre" />
        </div>

        <div className='w-full p-4'>

            <div className=' text-center mt-5 font-bold text-lg'>{data.name}</div>
            <div className=' text-center mt-2'><b>{data.description[0].key}:</b> {data.description[0].value}</div>
            {/* <div className=' text-center mt-2 text-zinc-400'>{data.purpose}</div> */}
            <div className=' text-center mt-2 font-bold text-sm'>{data.price.toLocaleString('en-US', { minimumFractionDigits: 2 })} USD</div>

            <div className='flex justify-center'>
                <button className='text-blue-600 hover:text-blue-400 text-center underline mt-2' onClick={e => {
                    e.preventDefault();
                    openModal();
                }}>
                    Learn more
                </button>
            </div>
            

            <div className="flex justify-center mt-3">
                <button onClick={e => {
                    e.preventDefault();
                    //openOrdersModal();
                    handleAddToCart(data);
                }} className='mx-auto bg-primary-100 hover:bg-orange-600 rounded-2xl px-2 py-1 text-white w-3/4 mt-2'>
                    Add To Cart
                </button>
            </div>

            <TrailerModal isOpen={isModalOpen} onClose={closeModal} data={data} />
            <OrderModal isOpen={isOrdersModalOpen} onClose={closeOrdersModal} data={data} />

        </div>

        

    </div>
  )
}

export default TrailersCard
