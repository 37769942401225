import React, { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export function AuthProvider({ children }){
    const [token, setToken] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [userId, setUserId] = useState(null);


    const login = (token, user_id, fullname, email, phoneNumber, companyName) =>{
        setToken(token);
        setFullName(fullname);
        setCompanyName(companyName);
        setUserId(user_id);
        setEmail(email);
        setPhoneNumber(phoneNumber);
        localStorage.setItem('navi$token', token)
        localStorage.setItem('navi$userId', user_id)
        localStorage.setItem('navi$fullname', fullname)
        localStorage.setItem('navi$email', email)
        localStorage.setItem('navi$phonenumber', phoneNumber)
        localStorage.setItem('navi$companyname', companyName)
    }

    const logout = (user)=>{
        setToken(null);
        setUserId(null);
        setFullName(null);
        setEmail(null);
        setPhoneNumber(null);
        localStorage.removeItem('navi$token')
        localStorage.removeItem('navi$userId')
        localStorage.removeItem('navi$fullname')
        localStorage.removeItem('navi$email')
        localStorage.removeItem('navi$phonenumber')
        localStorage.removeItem('navi$companyname')
    }

    const isLoggedIn = () =>{
        let savedToken = localStorage.getItem('navi$token');
        let userId = localStorage.getItem('navi$userId');
        let fullname = localStorage.getItem('navi$fullname');
        let email = localStorage.getItem('navi$email');
        let phone = localStorage.getItem('navi$phonenumber');
        let companyname = localStorage.getItem('navi$companyname');

        if(savedToken){
            setToken(savedToken);
            setFullName(fullname);
            setEmail(email);
            setPhoneNumber(phone);
            setUserId(userId);
            setCompanyName(companyname);
        }
    }

    useEffect(()=>{
        isLoggedIn();
    },[])

    return (<AuthContext.Provider  value={{login, logout, fullName, email, phoneNumber, companyName, userId, token}}>{children}</AuthContext.Provider>)
}