const servicesData = [
    {
        id: 0,
        image : require('../assets/images/tyre-service.jpg'),
        title: "Tyre Maintenance",
        description: "Our comprehensive tyre maintenance services are designed to keep your tyres performing at their best. We offer:",
        services: [
            "Wheel Alignment and Balancing: Ensures even tyre wear and improved handling.",
            "Tyre Rotation and Pressure Checks: Extends tyre lifespan and optimizes fuel efficiency.",
            "Puncture Repairs: Quick fixes for damaged tyres to keep you safely on the road",
            "Seasonal Tyre Changes: Switch to winter or summer tyres for optimal seasonal performance."
        ],
        summary : "With our maintenance services, your tyres stay road-ready, providing a safer and smoother driving experience"
    },
    {
        id: 1,
        image : require('../assets/images/delivery.webp'),
        title: "Tyre Delivery",
        description: "Our efficient tyre delivery services bring convenience right to your doorstep. Benefits include",
        services: [
            "Timely and Reliable Service: Get your tyres delivered on time, every time.",
            "Skip the trip to the shop—our team can install your tyres at your location.",
            "Bulk Deliveries for Fleets: For businesses, we offer bulk delivery options to keep fleet operations running smoothly."
        ],
        summary : "Enjoy fast, flexible delivery options designed to meet your needs and schedule."
    }
    // {
    //     image : require('../assets/images/twomaintenance.png'),
    //     title: "Truck Maintenance",
    //     description: "Our comprehensive truck maintenance service ensures that your fleet stays in optimal condition, minimizing downtime and maximizing performance. We cover everything from regular oil changes to brake system inspections, ensuring your trucks run smoothly and efficiently. Our tire maintenance services, including pressure monitoring, rotation, and alignment, help extend tire life and improve fuel efficiency. We also offer advanced engine diagnostics to detect and resolve issues early, along with battery testing, filter replacements, and transmission servicing. In addition, we provide suspension and steering checks, cooling system maintenance, and exhaust system inspections to ensure your trucks meet safety and emission standards. With our thorough approach to maintenance, we help keep your vehicles on the road longer and reduce unexpected breakdowns."
    // },
    // {
    //     image : require('../assets/images/gps2-removebg-preview.png'),
    //     title: "GPS Device Installation",
    //     description: "We offer professional GPS device installation to enable seamless tracking and monitoring of your vehicles. Our GPS systems provide real-time location updates, allowing you to track your fleet’s movements, monitor driver behavior, and ensure timely deliveries. With features like geofencing, route history, and automated alerts, our GPS installations are key to improving fleet efficiency and security."
    // },
    // {
    //     image : require('../assets/images/fuel_sensor-removebg-preview.png'),
    //     title: "Fuel Sensor Installation",
    //     description: "Our advanced fuel sensor installation service ensures real-time monitoring of your fuel levels, helping you detect any inconsistencies or fuel theft. With precise measurement technology, our sensors provide accurate data on fuel consumption and tank levels, enabling you to optimize fuel efficiency across your fleet. This service is essential for controlling operational costs and improving overall fuel management."
    // },
    // {
    //     image : require('../assets/images/speedometer-148960_640.webp'),
    //     title: "Speed Governors Installation",
    //     description: "Our speed governors installation service ensures your vehicles comply with speed regulations, enhancing safety for drivers and reducing the risk of accidents. By automatically limiting the maximum speed of your fleet, these devices help prevent reckless driving and lower fuel consumption. This service is crucial for promoting safe driving practices while reducing maintenance costs."
    // },
    // {
    //     image : require('../assets/images/fleetmanagement.jpg'),
    //     title: "Fleet Management Solutions",
    //     description: "Our fleet management solutions offer end-to-end control of your fleet, from route planning and driver management to fuel monitoring and maintenance scheduling. With our integrated platform, you can track vehicle performance, monitor compliance, and gain insights into operational efficiencies. We tailor our solutions to meet the unique needs of your business, helping you streamline operations and reduce costs."
    // }
];

export default servicesData;
