import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../context/AuthContext';

function Login() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { login } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleSubmit = (e) =>{ 
        setLoading(true);
        e.preventDefault();

        if(email == null || password == null){
            toast.error("All Fields Must be Filled");
            setLoading(false);
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/user_login`,{
            method: 'POST',
            headers : {
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                email, password
            })
        })
        .then(data =>{
            if(data.ok){
                data.json().then(result => {
                    console.log(result)
                    login(result.token, result.userId, result.first_name + " " + result.second_name, result.email, result.phoneNumber, result.companyName)
                    toast.success("Success. Proceed to Login")
                    setTimeout(()=>{
                        setLoading(false);
                        navigate("/");
                    },300)
                })
            }else{
                toast.error("Failed to Login");
                setLoading(false);
            }
        })
        .catch((err)=>{
            toast.error("Failed to Login:" + err)
            setLoading(false)
        })
    }
  return (
    <div className='p-10 px-5 lg:px-24 gap-52 font-montserrat mt-16  min-h-screen' style={{ backgroundColor: "#f3f3f3"}}>
        <ToastContainer />
      <div className='flex w-5/6 lg:w-1/3 mx-auto bg-white rounded-lg mt-40 lg:mt-20'>
            <form className='w-full p-5 text-xs'>
                <div className='text-center mb-5 font-bold tracking-wider text-lg'>Login</div>
                <div className='w-full'>
                    <input type='text' placeholder="Email" onChange={e => setEmail(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                </div>

                <div className='w-full mt-5'>
                    <input type='password' placeholder="Password" onChange={e => setPassword(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                </div>

                { !loading && <div className='flex justify-center mb-3'>
                    <button onClick={e => handleSubmit(e)} className='w-full text-center bg-primary-100 mt-5 p-2 text-white rounded-lg'>Login</button>
                </div> }
                {
                    loading && <div className='flex justify-center mb-3'>
                    <button onClick={e => e.preventDefault()} className='w-full text-center bg-zinc-500 mt-5 p-2 text-white rounded-lg'>Loading ....</button>
                </div>
                }

                <Link to={"/register"} className='cursor-pointer'>I don't have an account? <span className='text-blue-700'>Register</span></Link>
            </form>
      </div>
    </div>
  )
}

export default Login
