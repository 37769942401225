import React, { useEffect } from 'react'
import useCart from '../context/CartContext';
import { Link } from "react-router-dom";
import { Checkmark } from 'react-checkmark';

function SuccesfulOrder() {
    const {  clearState } = useCart();

    useEffect(()=>{
        clearState();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);
  return (
    <div className='mt-28 min-h-screen'>
        <div className='mt-10 lg:mt-28 flex justify-center mb-10'>
            <div className='block mt-28'>
                <div className='flex justify-center ml-5 mb-5 lg:mb-10'>
                    <Checkmark size='xxLarge' />
                </div>
                <div className="flex justify-center text-center  px-10 ml-5 text-2xl mb-5 lg:10">
                    Your Order Has Been Placed Succesfully. 
                </div>
                <div className="flex justify-center text-center px-10 ml-5 text-lg text-gray-600  mb-3 lg:mb-5">
                    You will be contacted by our sales agent as soon as possible to make arrangements on delivery.
                </div>
                <div className="flex justify-center text-center mb-4 px-10 ml-5 text-lg">
                    Thank You!
                </div>
                <div className="flex justify-center text-center px-10 ml-5 mb-5 text-lg" style={{fontSize:'3em'}}>&#x1F38A;</div>
                <div className="flex justify-center px-10 ml-5 ">
                    <Link to={'/'}><div className="w-48 flex justify-center p-2 bg-black hover:bg-gray-600 text-white rounded-lg text-sm" >
                            HOME
                    </div></Link>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default SuccesfulOrder
