import React from 'react'
import ServiceCard from './ServiceCard'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import servicesData from '../assets/servicesData';
import { Link } from 'react-router-dom';

function TopServices() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
  return (
    <div className='p-10 px-5 lg:px-24 gap-52'>
        <div className='flex justify-between items-center'>
            <div className='text-xl font-bold'>Services We Offer</div>
            <Link to={"/services"} onClick={()=>{
                scrollToTop();
            }} className='text-xs lg:text-sm bg-black hover:bg-gray-800 text-white flex items-center gap-1 py-1 px-4 rounded-3xl'>
                <span>View more</span>
                <ArrowRightAltIcon />
            </Link>
        </div>
        
        <div className='bg-white p-2 block lg:flex flex-wrap gap-28 mx-5 lg:mx-20 my-5 lg:my-10 justify-center'>
            {
                servicesData.map( item => <ServiceCard data={item} />)
            }
            
        
        </div>
    </div>
  )
}

export default TopServices
