import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { AuthContext } from '../context/AuthContext';
import useCart from '../context/CartContext';
import PersonIcon from '@mui/icons-material/Person';

function Header() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isUserMenuOpen, setUserMenuOpen] = useState(false); // State for user menu dropdown
    const dropdownRef = useRef(null); // Ref to track the dropdown
    const userMenuRef = useRef(null); // Ref for user menu dropdown

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
            if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
                setUserMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const { token, fullName, logout } = useContext(AuthContext);
    const { products } = useCart();

    return (
        <div className='flex align-middle px-2 lg:px-5 py-2 font-semibold w-full fixed bg-white top-0'>
            <div className='w-1/4 p-2 flex items-center'>
                <Link to={"/"} onClick={() => { scrollToTop(); setDropdownOpen(false) }}>
                    <img src={require('../assets/images/navi-logo.png')} className='w-32 object-contain' alt="Logo" />
                </Link>
            </div>
            <ul className='flex gap-4 ml-20 w-3/4 p-2 justify-end items-center text-sm lg:text-base'>
                {/* Links visible on all screens */}
                <Link to={"/"} onClick={() => scrollToTop()} className='hover:text-primary-100'>Home</Link>
                <Link to={"/services"} onClick={() => scrollToTop()} className='hover:text-primary-100'>Services</Link>
                <Link to={"/cart"} onClick={() => scrollToTop()} className='flex items-center gap-0 hover:text-primary-100'>
                    Cart 
                    <ShoppingCartOutlinedIcon sx={{ fontSize:'18px', paddingLeft:"2px" }}/>
                    <sup className='sups'>{products.length}</sup>
                </Link>
                {/* <Link to={"/products"} onClick={() => scrollToTop()} className='hover:text-primary-100'>Products</Link> */}

                {/* Links for larger screens (visible) */}
                <div className='hidden lg:flex gap-4 items-center'>
                    <Link to={"/sales_branches"} onClick={() => scrollToTop()} className='hover:text-primary-100'>Sales Branch</Link>
                    <Link to={"/contact_us"} onClick={() => scrollToTop()} className='hover:text-primary-100'>Contact Us</Link>

                    {token ? (
                        <div ref={userMenuRef} className='relative'>
                            <button
                                onClick={() => setUserMenuOpen(!isUserMenuOpen)}
                                className="hover:bg-zinc-600 bg-zinc-600 p-1 px-2 rounded-lg text-white flex items-center gap-2 text-sm"
                            >
                                <PersonIcon sx={{fontSize:"16px"}} />
                                {fullName}
                            </button>
                            {isUserMenuOpen && (
                                <div className='absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2'>
                                    <button
                                        onClick={() => { logout(); setUserMenuOpen(false); }}
                                        className='block w-full text-left py-1 px-4 hover:bg-gray-100'
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to={"/login"} onClick={() => scrollToTop()} className="hover:bg-orange-600 bg-primary-100 p-1 px-2 rounded-lg text-white">
                            Login / Register
                        </Link>
                    )}
                </div>

                {/* Dropdown for smaller screens */}
                <div className='relative lg:hidden' ref={dropdownRef}>
                    <button onClick={() => setDropdownOpen(!isDropdownOpen)} className='hover:text-primary-100'>
                        <MenuIcon sx={{ fontSize: 20 }} />
                    </button>
                    {isDropdownOpen && (
                        <div className='absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md pb-2'>
                            <Link to={"/sales_branches"} onClick={() => { scrollToTop(); setDropdownOpen(false) }} className='block py-1 px-2'>Sales Branch</Link>
                            <Link to={"/contact_us"} onClick={() => { scrollToTop(); setDropdownOpen(false) }} className='block py-1 px-2'>Contact Us</Link>
                            {token ? (
                                <button
                                    onClick={() => { logout(); setDropdownOpen(false); }}
                                    className="block w-full text-left py-1 hover:bg-gray-100 bg-zinc-600 ml-1 p-1 px-2 rounded-lg text-white"
                                >
                                    Logout
                                </button>
                            ) : (
                                <Link to={"/login"} onClick={() => scrollToTop()} className="hover:bg-orange-600 bg-primary-100 ml-1 p-1 px-2 rounded-lg text-white">
                                    Login / Register
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </ul>
        </div>
    );
}

export default Header;
