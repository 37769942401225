import React, { useState } from 'react';
import OrderModal from './OrderModal';
import useCart from '../context/CartContext';

const ProductModal = ({ isOpen, onClose, data }) => {
  

    const [isOrdersModalOpen, setOrdersModalOpen] = useState(false);

    const openOrdersModal = () => setOrdersModalOpen(true);
    const closeOrdersModal = () => setOrdersModalOpen(false);

    const { addToCart } = useCart();
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = (input) => {
        addToCart({ ...input, quantity: Number(quantity) });
    };

    if (!isOpen) return null; 

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={onClose}></div>

      {/* Modal content */}
      <div className="relative bg-white w-5/6 lg:w-1/2 p-6 rounded-lg shadow-lg z-20">
        <div className="flex justify-end">
          <button className="font-bold text-red-600" onClick={onClose}>X</button>
        </div>
        <div className="mt-4">
            <div className='flex justify-center w-full mb-0 lg:mb-4 h-20 lg:h-56'>
                <img src={data.image} className='object-contain' alt="Tyre" />
            </div>

            <div className='w-full p-4'>  

            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-xs lg:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-2 lg:px-6 py-3">
                                Key
                            </th>
                            <th scope="col" class="px-2 lg:px-6 py-3">
                                Value
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Item Name
                            </th>
                            <td class="px-2 lg:px-6 py-4 font-bold text-sm lg:text-lg">
                                {data.name}
                            </td>
                        </tr>
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Vehicle
                            </th>
                            <td class="px-2 lg:px-6 py-4">
                                {data.vehicle}
                            </td>
                        </tr>
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Purpose
                            </th>
                            <td class="px-2 lg:px-6 py-4">
                                {data.purpose}
                            </td>
                        </tr>
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hidden lg:table-row">
                            <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Benefits and Features
                            </th>
                            <td class="px-2 lg:px-6 py-4">
                                {data.description.map(item => (
                                    <div className='border-l-2 border-primary-100 px-2 mb-2'>{item}</div>
                                ))}
                            </td>
                        </tr>
                        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Price
                            </th>
                            <td class="px-2 lg:px-6 py-4">
                                <div>KES. {data.price - 500} each  - 1 - 20 tyres</div>
                                <div>KES. {data.price - 1000} each - 21 - 40 tyres</div>
                                <div>KES. {data.price - 2000} each - 41 + tyres</div>
                            </td>
                        </tr>

                    
                    </tbody>
                </table>

                <div className='block lg:hidden'>
                    <div class="px-2 lg:px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white text-sm text-center mt-2">
                        Benefits and Features
                    </div>
                    <div class="px-2 lg:px-6 py-1">
                        {data.description.map( item => (
                                <div className='border-l-2 border-primary-100 px-2 mb-2 text-xs'>{item}</div>
                        ))}
                    </div>
                </div>

            </div>

            <div className="flex justify-center mt-3">
                    <button onClick={e => {
                        e.preventDefault();
                        handleAddToCart(data)
                        //openOrdersModal();
                    }} className='mx-auto bg-primary-100 hover:bg-orange-600 rounded-2xl px-2 py-1 text-white w-1/2 lg:w-1/4 mt-2'>
                        Add To Cart
                    </button>
                </div>

            <OrderModal isOpen={isOrdersModalOpen} onClose={closeOrdersModal} data={data} />

            </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;