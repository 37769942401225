import React from 'react'
import Mason from '../assets/images/truck-maintain.jpeg';
import { Link } from 'react-router-dom';

function ServicesBanner() {
  return (
    <div
      className="w-full h-96 bg-cover bg-center text-white flex items-center justify-center mt-16 font-montserrat"
      style={{ backgroundImage: `url(${Mason})` }}
    >
        <div className="bg-black bg-opacity-60 p-4 rounded-md w-full h-full flex justify-center items-center">
            <div className='w-1/2 mx-auto'>
                <h1 className="text-4xl font-bold text-center">Services</h1>
                <div className="text-sm text-center flex justify-center items-center gap-2 font-bold">
                <Link to={"/"} className="text-primary-100 hover:text-orange-700">Home</Link>
                <span>{">>"}</span>
                <span>Services</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesBanner
