import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GetAQuoteModal = ({ isOpen, onClose, data }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  let  htmlTemplate =  `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${data.title} Service Quote Requested By Customer</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #fff;
                    margin: 0;
                    padding: 20px;
                    color: #333;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    border: 1px solid #333;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                }
                .header {
                    background-color: #333;
                    color: #fff;
                    padding: 10px;
                    text-align: center;
                }
                .content {
                    padding: 20px;
                }
                .footer {
                    background-color: #333; /* Black background */
                    color: #fff;
                    text-align: center;
                    padding: 10px;
                }
                .label {
                    font-weight: bold;
                    color: #333; 
                }
                span {
                    margin-right: 5px;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="header">
                    <h1>${data.title} Service Quote Requested By Customer</h1>
                </div>
                <div class="content">
                    <p><span class="label">Customer Name:</span>${firstName} ${lastName}</p>
                    <p><span class="label">Email:</span> ${email}</p>
                    <p><span class="label">Phone Number:</span>${phoneNumber}</p>
                </div>
                <div class="footer">
                    <p>Reach out to the customer!</p>
                </div>
            </div>
        </body>
        </html>
        `

  let sentData = { 
      subject: `${data.title} Service Quote Requested By Customer`,
      htmlTemplate
  };

  let title = data.title

    let larkMessage =
        `🚨 ${sentData.subject.toUpperCase()}\nCustomer Name: ${firstName} ${lastName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nService: ${title}`;

    sentData = { ...sentData, larkMessage}

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if(firstName == null || lastName == null || email == null || phoneNumber == null){
        toast("All Fields Must be filled",{
          type:"error",
          autoClose: 500
        })
        setLoading(false);
        return;
      }

    fetch(`${process.env.REACT_APP_API_URL}/send_email`,{
        method: 'POST',
        headers: {
          "Content-Type":"application/json"
        },
        body: JSON.stringify(sentData)
      })
      .then(()=>{
        toast("Inquiry for Quote Has Been Received. We will get back to you as soon as possible",{
          type:"success",
          autoClose: 1500
        });
        setLoading(false);
        setFirstName(null);
        setLastName(null);
        setPhoneNumber(null);
        setEmail(null);
  
        setTimeout(()=>{
          onClose();
        },2000)
      })
 
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20">
        <ToastContainer />
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={onClose}></div>

      {/* Modal content */}
      <div className="relative bg-white w-5/6 lg:w-1/2 p-6 rounded-lg shadow-lg z-20 text-sm">
        <form>
            <div className='mb-2 text-center text-xl uppercase font-montserrat font-bold tracking-wide'>Get A Quote</div>
            <div className='mb-5 text-gray-600 text-center text-sm'>{data.title}</div>
            <div className='flex w-full gap-4'>
                <div className='w-1/2'>
                    <input type="text" onChange={e => {
                        setFirstName(e.target.value)
                    }} className='border border-gray-300 p-2 w-full rounded-lg' placeholder="First Name" required />
                </div>
                <div className='w-1/2'>
                    <input type="text" onChange={e => {
                        setLastName(e.target.value)
                    }} className='border border-gray-300 p-2 w-full rounded-lg' placeholder="First Name" required />
                </div>
            </div>
            <div>
                <input type="email" onChange={e => {
                    setEmail(e.target.value);
                }} className='border border-gray-300 p-2 w-full rounded-lg mt-5' placeholder='johndoe@gmail.com' required />
            </div>
            <div>
                <input type="text" 
                onChange={e => {
                    setPhoneNumber(e.target.value);
                }} className='border border-gray-300 p-2 w-full rounded-lg mt-5' placeholder='Phone Number' required />
            </div>

            <div className="mt-5 text-center font-mono bg-blue-200 p-2 rounded-lg">
                * Thank you! We will get back to you as soon as you submit this quote inquiry form.
            </div>
            

            <div className='flex justify-center gap-3'>
                <button onClick={onClose} className='border border-primary-100 text-primary-100 hover:bg-orange-600 hover:text-white py-2 px-4 rounded-lg w-1/2 lg:w-1/4 mt-5 text-xs lg:text-sm'>Cancel</button>
                { loading ?
                    <button onClick={e => {
                        e.preventDefault();
                    }} className='bg-gray-400 py-2 px-4 rounded-lg text-black w-1/2 lg:w-1/4 mt-5 text-xs lg:text-sm'>Loading ...</button>
                :
                    <input onClick={e => {
                        handleSubmit(e);
                    }} type="submit" value="Get A Quote" className='bg-primary-100 hover:bg-orange-600 py-2 px-4 rounded-lg text-white w-1/2 lg:w-1/4 mt-5 text-xs lg:text-sm'/>
                }
            </div>
            
        </form>
      </div>
    </div>
  );
};

export default GetAQuoteModal;