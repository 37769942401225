import React, { useContext, useEffect, useState } from 'react'
import useCart from '../context/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../context/AuthContext';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import ApartmentIcon from '@mui/icons-material/Apartment';

function PlaceOrder() {
    const { products } = useCart();
    const { fullName, token, email, phoneNumber, companyName  } = useContext(AuthContext);

    const [delivery, setDelivery] = useState(false);
    const [pickup, setPickup] = useState(true);
    const [deliveryLocation, setDeliveryLocation] = useState(null);
    const [branch, setBranch] = useState('Nairobi Branch');
    const [loading, setLoading] = useState(false);
    const [requiresDeposit, setRequiresDeposit] = useState(false);
    const [depositConfirmed, setDepositConfirmed] = useState(false);
    const [goodFaithDeposit, setGoodFaithDeposit] = useState(0);

    const handleDeliveryChange = () => {
        setDelivery(true);
        setPickup(false);
    };

    const navigate = useNavigate();

    const handlePickupChange = () => {
        setPickup(true);
        setDelivery(false);
    };

    useEffect(()=>{
        const hasSemitrailer = products.some(product => product.type === "semitrailer");
        setRequiresDeposit(hasSemitrailer);
        let numOfTrucks = 0;
        products.map( product => {
            if(product.type === "semitrailer"){
                numOfTrucks += product.quantity;
            }
        })
        let amount = numOfTrucks * 1000;
        setGoodFaithDeposit(amount);
    },[products])

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();

        if(requiresDeposit && !depositConfirmed) { 
            toast("Please confirm deposit payment before submitting.", {
                type: "error",
                autoClose: 2000
            });
            setLoading(false);
            return;
        }

        let htmlTemplate = null;

        let productSummaryHTML = products.map(product =>
            `<p>- ${product.name}: ${product.quantity} unit(s) @ ${product.type === "tyre" ? "Ksh" : "USD"} ${product.discountedPrice.toLocaleString()}</p>`
        ).join('');
        
        if (pickup) {
            htmlTemplate = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>New Order Notification</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #fff;
                            margin: 0;
                            padding: 20px;
                            color: #333;
                        }
                        .container {
                            max-width: 600px;
                            margin: 0 auto;
                            border: 1px solid #333;
                            border-radius: 5px;
                            overflow: hidden;
                            background-color: #fff;
                        }
                        .header {
                            background-color: #333;
                            color: #fff;
                            padding: 10px;
                            text-align: center;
                        }
                        .content {
                            padding: 20px;
                        }
                        .footer {
                            background-color: #333;
                            color: #fff;
                            text-align: center;
                            padding: 10px;
                        }
                        .label {
                            font-weight: bold;
                            color: #333;
                        }
                        span {
                            margin-right: 5px;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="header">
                            <h1>New Order Notification From Ecommerce Site</h1>
                        </div>
                        <div class="content">
                            <p><span class="label">Full Name:</span>${fullName}</p>
                            <p><span class="label">Company:</span>${companyName}</p>
                            <p><span class="label">Email:</span>${email}</p>
                            <p><span class="label">Phone Number:</span>${phoneNumber}</p>
                            <p><span class="label">Pickup Location:</span>${branch}</p>
                            <p><span class="label">Products:</span></p>
                            ${productSummaryHTML}
                            ${requiresDeposit ? `<p><span class="label">Good Faith Deposit Paid:</span>${depositConfirmed ? "Yes" : "No"}</p>` : ""}
                        </div>
                        <div class="footer">
                            <p>Reach out to the customer!</p>
                        </div>
                    </div>
                </body>
                </html>
            `;
        } else {
            htmlTemplate = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>New Order Notification</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #fff;
                            margin: 0;
                            padding: 20px;
                            color: #333;
                        }
                        .container {
                            max-width: 600px;
                            margin: 0 auto;
                            border: 1px solid #333;
                            border-radius: 5px;
                            overflow: hidden;
                            background-color: #fff;
                        }
                        .header {
                            background-color: #333;
                            color: #fff;
                            padding: 10px;
                            text-align: center;
                        }
                        .content {
                            padding: 20px;
                        }
                        .footer {
                            background-color: #333;
                            color: #fff;
                            text-align: center;
                            padding: 10px;
                        }
                        .label {
                            font-weight: bold;
                            color: #333;
                        }
                        span {
                            margin-right: 5px;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="header">
                            <h1>New Order Notification From Ecommerce Site</h1>
                        </div>
                        <div class="content">
                            <p><span class="label">Full Name:</span>${fullName}</p>
                            <p><span class="label">Company:</span>${companyName}</p>
                            <p><span class="label">Email:</span>${email}</p>
                            <p><span class="label">Phone Number:</span>${phoneNumber}</p>
                            <p><span class="label">Delivery Location:</span>${deliveryLocation}</p>
                            <p><span class="label">Products:</span></p>
                            ${productSummaryHTML}
                            ${requiresDeposit ? `<p><span class="label">Good Faith Deposit Paid:</span>${depositConfirmed ? "Yes" : "No"}</p>` : ""}
                        </div>
                        <div class="footer">
                            <p>Reach out to the customer!</p>
                        </div>
                    </div>
                </body>
                </html>
            `;
        }
        
        let sentData = { 
        subject: "New Order From Navi Ecommerce Website",
        htmlTemplate
        };

        let larkMessage = '';

        let productDetails = products.map(product => 
            `${product.name}: ${product.quantity} unit(s) at ${product.type === "tyre" ? "Ksh" : "USD"} ${product.discountedPrice.toLocaleString()} each (Total: ${product.type === "tyre" ? "Ksh" : "USD"} ${(product.discountedPrice * product.quantity).toLocaleString()})`
        ).join('\n');
        
        let depositInfo = depositConfirmed 
            ? `Good Faith Deposit: Paid` 
            : ``;
        
        if (pickup) {
            larkMessage = 
            `🚨 ${sentData.subject.toUpperCase()}\nCustomer Name: ${fullName}\nCompany Name: ${companyName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nPickup branch: ${branch}\n\nProduct Details:\n${productDetails}\n\n${depositInfo}`;
        } else {
            larkMessage =
            `🚨 ${sentData.subject.toUpperCase()}\nCustomer Name: ${fullName}\nCompany Name: ${companyName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nDelivery Location: ${deliveryLocation}\n\nProduct Details:\n${productDetails}\n\n${depositInfo}`;
        }        

        sentData = { ...sentData, larkMessage, products }

        fetch(`${process.env.REACT_APP_API_URL}/send_email`,{
        method: 'POST',
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(sentData)
        })
        .then(()=>{
        toast("Order Succesfully Placed. You Will be contacted as soon as possible",{
            type:"success",
            autoClose: 1500
        });
        setLoading(false);
        setPickup(true);
        setDelivery(false);
        setDeliveryLocation(null);
        setBranch(null);

        setTimeout(()=>{
            navigate("/order_succesfull");
        },2000)
        
        })
    }

  return (
    <div className='mt-28 '>
        <ToastContainer />
        <form onSubmit={handleSubmit} className='w-5/6 lg:w-3/4 mx-auto p-5 lg:p-10 bg-gray-100 my-10'>
            <div className='mb-5 text-center text-xl uppercase font-montserrat font-bold tracking-wide'>Place Order</div>

            <div className='border border-gray-300 p-4 rounded-lg'>
                <div className='font-bold'>Customer Details</div>
                <div className='flex w-full gap-4'>
                    <div className='w-full block lg:flex gap-10'>
                        <div className='flex items-center gap-3'><PersonIcon sx={{fontSize: '18px'}} /> {fullName}</div>
                        <div className='flex items-center gap-3'><EmailIcon sx={{fontSize: '16px'}} /> {email}</div>
                        <div className='flex items-center gap-3'><PhoneIcon sx={{fontSize: '16px'}} /> {phoneNumber}</div>
                        <div className='flex items-center gap-3'><ApartmentIcon sx={{fontSize: '16px'}} /> {companyName}</div>
                    </div>
                </div>
            </div>

            <div className='bg-gray-100 border border-gray-300 p-2 mt-5 mb-2 rounded-lg'> 
                <div className='text-center mx-auto mb-5 font-bold uppercase text-sm'>Products Summary: </div>
                <div className='w-full lg:w-11/12 mx-auto text-xs'>
                {
                    products.length > 0 && products.map( product => (
                        <div className='flex mb-2'>
                            <div className='text-xs w-1/3 font-bold'>{product.name} </div>
                            <div className='w-1/12 text-left'>X</div>
                            <div className='w-1/6'>{product.quantity} unit (s)</div>
                            <div className='w-1/6'>@ {product.type == "tyre" ? "Ksh" : "USD"} {Number(product.discountedPrice).toLocaleString()}</div>
                            <div className='w-1/6 font-bold'>{product.type == "tyre" ? "Ksh" : "USD"} {(product.discountedPrice * product.quantity).toLocaleString()}</div>
                        </div>
                    ))
                }
                </div>
            </div>

            {requiresDeposit && (
                <div className="bg-zinc-500 border-l-4 border-blue-300 text-white p-4 mb-2" role="alert">
                    <p className="font-bold text-lg">Good Faith Deposit Required</p>
                    <p className='text-xs'>To secure your semitrailer order, a good faith deposit of Ksh 1000 per semitrailer is required. This covers initial handling and ensures item availability.</p>
                    <p className='text-xs mt-2'>Kindly deposit <span className='underline underline-offset-4 font-bold text-sm'>Ksh {goodFaithDeposit}</span> to the following bank account:</p>

                    <div className='mt-5 text-sm lg:text-base'>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-1/2 lg:w-1/4 font-bold'>
                                Beneficiary
                            </div>
                            <div>
                                BUFFALO TBS ENTERPRISES CO LTD
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-1/2 lg:w-1/4 font-bold'>
                                Bank name
                            </div>
                            <div>
                                DIAMOND TRUST BANK
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-full lg:w-1/4 font-bold'>
                                Account Number (KSH)
                            </div>
                            <div>
                                0924687002
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-full lg:w-1/4 font-bold'>
                                Account Number (USD)
                            </div>
                            <div>
                                0924687001
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-full lg:w-1/4 font-bold'>
                                Branch
                            </div>
                            <div>
                                WESTGATE
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-full lg:w-1/4 font-bold'>
                                Bank Code
                            </div>
                            <div>
                                63
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-full lg:w-1/4 font-bold'>
                                Branch Code
                            </div>
                            <div>
                                006
                            </div>
                        </div>
                        <div className='block lg:flex mb-1 gap-4'>
                            <div className='w-full lg:w-1/4 font-bold'>
                                Swift Code
                            </div>
                            <div>
                                DTKEKENA
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <label>
                            <input 
                                type="checkbox" 
                                checked={depositConfirmed} 
                                onChange={() => setDepositConfirmed(!depositConfirmed)} 
                            />
                            <span className="ml-2 text-sm">I confirm that I have made the deposit payment.</span>
                        </label>
                    </div>
                </div>
            )}
            
            <div className='block lg:flex items-center gap-5'>
                <div className='mt-5 text-sm w-full lg:w-1/2'>
                    <div>Do you want the products to be delivered to your location?</div>
                    <div>
                    <input
                        type="radio"
                        id="yes"
                        name="delivery"
                        checked={delivery}
                        onChange={handleDeliveryChange}
                    />
                    <label htmlFor="yes" className='ml-2'>Yes</label>
                    </div>
                    {delivery && (
                    <input
                        type="text"
                        placeholder='Enter Delivery Location'
                        className='p-2 border border-gray-300 rounded-lg my-2 w-full'
                        value={deliveryLocation}
                        onChange={e => setDeliveryLocation(e.target.value)}
                    />
                    )}
                    <div>
                    <input
                        type="radio"
                        id="no"
                        name="delivery"
                        checked={pickup}
                        onChange={handlePickupChange}
                    />
                    <label htmlFor="no" className='ml-2'>No, I will pick them up at the <b>Nairobi Branch</b> store.</label>
                    </div>
                    {/* {pickup && (
                    <div>
                        <div className='mt-2 font-bold mb-1 ml-1'>Select branch:</div>
                        <select className='p-2 rounded-lg border border-gray-300' onChange={e => {
                        setBranch(e.target.value)
                        }}>
                        <option value={"Nairobi Branch"}>Nairobi Branch</option>
                        <option value={"Kisumu Branch"}>Kisumu Branch</option>
                        <option value={"Eldoret Branch"}>Eldoret Branch</option>
                        <option value={"Mombasa Branch"}>Mombasa Branch</option>
                        </select>
                    </div>
                    )} */}
                </div>

                <div className='border border-gray-300 p-2 rounded-lg my-5 w-full lg:w-1/2'>
                    <div className='font-bold text-center'>Our Sales Agents Contacts</div>
                    <div className='block lg:flex justify-between mx-auto w-full px-5'>
                        <div>
                            <div className='mt-5 font-bold'>Tyres Sales Agent</div>
                            <div className='flex items-center gap-3'><PersonIcon sx={{fontSize: '18px'}} />Margret Kyened</div>
                            <div className='flex items-center gap-3'><EmailIcon sx={{fontSize: '16px'}} />service@rundake.com</div>
                            <div className='flex items-center gap-3'><PhoneIcon sx={{fontSize: '16px'}} />0791737293</div>
                        </div>
                        <div>
                            <div className='mt-5 font-bold'>Semi-trailers Sales Agent</div>
                            <div className='flex items-center gap-3'><EmailIcon sx={{fontSize: '16px'}} />marketing@buffaloke.com</div>
                            <div className='flex items-center gap-3'><PhoneIcon sx={{fontSize: '16px'}} />0720818744</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5 text-center font-mono bg-blue-200 p-2 rounded-lg text-sm">
                * Thank you! We will get back to you as soon as you submit this order form.
            </div>

            <div className='flex justify-center gap-3'>
            <button
                type="button"
                onClick={()=>{
                navigate('/cart');
                window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className='text-sm border border-primary-100 text-primary-100 hover:bg-orange-600 hover:text-white py-2 px-4 rounded-lg w-1/2 lg:w-1/4 mt-5'
            >
                Cancel
            </button>
            {
                loading ? 
                <button
                className='bg-gray-400 py-2 px-4 rounded-lg text-black w-1/2 lg:w-1/4 mt-5 text-sm'
                >Loading ... </button>
                :
                <input
                onClick={(e)=>{
                    handleSubmit(e);
                }}
                type="submit"
                value="Submit Order"
                className='bg-primary-100 hover:bg-orange-600 py-2 px-4 rounded-lg text-white w-1/2 lg:w-1/4 mt-5 text-sm'
                />
            }
            
            </div>
        </form>
    </div>
  )
    }

export default PlaceOrder;
