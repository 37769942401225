import React from 'react'
import { Link } from 'react-router-dom';

function ServiceCard({ data }) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className='w-full lg:w-1/4 rounded-lg p-4 mb-5' style={{backgroundColor:"#F3F3F3"}}>

        <div className='flex justify-center w-full h-64 mb-0 lg:mb-4 my-5'>
            <img src={data.image} className='object-contain' alt="Image" />
        </div>

        <div className='text-lg font-bold text-center capitalize mt-5'>
            {data.title}
        </div>

        <div className='flex justify-center mt-5 text-center'>
            <Link to={`/services#${data.id}`} className='bg-black hover:bg-gray-800 text-white rounded-3xl w-1/2 px-2 py-1 lg:py-2'>Learn more</Link>
        </div>
      
    </div>
  )
}

export default ServiceCard
