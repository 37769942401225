import React from 'react'

function Partners() {
    const data = [ , 2, 3, 4, 5, 6, 7 , 8 ];
  return (
    <div className='my-10 bg-white'>
        <div className='text-xl lg:text-2xl font-bold text-center'>Our Partners</div>
        <div className='block lg:flex w-3/4 mx-auto gap-20 flex-wrap justify-center'>
            <img src={require('../assets/images/logo1.png')} className='w-64 object-contain mb-2'/>
            <img src={require('../assets/images/logo2.png')} className='w-64 object-contain mb-2'/>
            <img src={require('../assets/images/logo3.png')} className='w-64 object-contain mb-2'/>
            <img src={require('../assets/images/logo4.png')} className='w-64 object-contain mb-2'/>
            <img src={require('../assets/images/logo5.png')} className='w-64 object-contain mb-2'/>
            <img src={require('../assets/images/logo6.png')} className='w-64 object-contain mb-2'/>
            <img src={require('../assets/images/logo7.png')} className='w-64 object-contain mb-2'/>
        </div>
    </div>
  )
}

export default Partners
