import ClearIcon from '@mui/icons-material/Clear';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import useCart from '../context/CartContext';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const Cart = () => {
    const { products, total, removeFromCart, updateQuantity } = useCart();
    const { token } = useContext(AuthContext);
    const [quantities, setQuantities] = useState(
        products.reduce((acc, product) => ({ ...acc, [product._id]: product.quantity }), {})
    );

    const handleRemoveFromCart = (product) => {
        removeFromCart(product);
    };

    const handleQuantityChange = (productId, value) => {
        const newQuantity = parseInt(value, 10);

        if (!isNaN(newQuantity) && newQuantity > 0) {
            setQuantities({ ...quantities, [productId]: newQuantity });
            updateQuantity(productId, newQuantity);
        }
    };

    const calculateDiscountedPrice = (type, price, quantity) => {
        let discount = 0;

        if(type == "tyre"){
            if (quantity >= 1 && quantity <= 20) {
                discount = 500;
            } else if (quantity >= 21 && quantity <= 40) {
                discount = 1000;
            } else if (quantity >= 41) {
                discount = 2000;
            }
        }else{

            if (quantity >= 1 && quantity <= 10) {
                discount = 1000;
            } else if (quantity >= 11 && quantity <= 20) {
                discount = 2000;
            } else if (quantity >= 21) {
                discount = 3000;
            }
        }

        return price - discount;
    };

    return (
        <div className="mb-10 mt-20 lg:mt-28 min-h-screen">
            {products.length > 0 ? 
            <div>
                <div className="text-center font-semibold font-mono mt-16 lg:ml-8">Your Cart</div>
                <div className="text-center mt-1 text-gray-500 lg:ml-8 mb-10">{products.length} item(s)</div>

                <div className='block lg:flex gap-2 w-3/4 mx-auto'>
                <div className='w-full lg:w-1/2 bg-gray-200 border border-gray-300 p-4 rounded-lg text-sm mx-auto mb-10'>
                    <div className='text-base font-bold font-montserrat'>Tyre Discounts</div>
                    <div className='flex items-center gap-2'><StarIcon sx={{fontSize:"16px", color:"#ffcf40"}} /> Order 1 - 20 Tyres Get 500 KES Discount on each tyre</div>
                    <div className='flex items-center gap-2'><StarIcon sx={{fontSize:"16px", color:"#ffcf40"}} /> Order 21 - 40 Tyres Get 1000 KES Discount on each tyre</div>
                    <div className='flex items-center gap-2'><StarIcon sx={{fontSize:"16px", color:"#ffcf40"}} /> Order 41 and more Tyres Get 2000 KES Discount on each tyre</div>
                </div>

                <div className='w-full lg:w-1/2 bg-gray-200 border border-gray-300 p-4 rounded-lg text-sm mx-auto mb-10'>
                    <div className='text-base font-bold font-montserrat'>Semi-Trailer Discounts</div>
                    <div className='flex items-center gap-2'><StarIcon sx={{fontSize:"16px", color:"#ffcf40"}} /> Order 1 - 10 Semi-trailers Get 1000 USD Discount on each trailer</div>
                    <div className='flex items-center gap-2'><StarIcon sx={{fontSize:"16px", color:"#ffcf40"}} /> Order 11 - 20 semi-trailers Get 2000 USD Discount on each trailer</div>
                    <div className='flex items-center gap-2'><StarIcon sx={{fontSize:"16px", color:"#ffcf40"}} /> Order 21 and more semi-trailers Get 3000 USD Discount on each trailer</div>
                </div>
                </div>

                <div className="border border-gray-200 rounded-lg p-1 w-3/4 mx-auto text-xs">
                    <div className="hidden sm:grid grid-cols-8 gap-4 bg-gray-50 p-2 text-xs font-semibold text-gray-600 border-b border-gray-200">
                        <div>Product</div>
                        <div>Image</div>
                        <div>Quantity</div>
                        <div>Original Price</div>
                        <div>Discounted Price</div>
                        <div>Total Price</div>
                        <div>Total Savings</div>
                        <div>Actions</div>
                    </div>
                    <div>
                        {products.filter(product => product.type === "tyre").map(product => (
                            <div
                                className="flex flex-col sm:grid sm:grid-cols-8 gap-4 items-center p-2 border-b border-gray-200 last:border-b-0"
                                key={product._id}
                            >
                                <div className="font-bold sm:col-span-1">{product.name}</div>
                                <div className="sm:col-span-1">
                                    <img
                                        src={product.type === "tyre" ? product.image : product.images[0]}
                                        className="w-20 h-20 lg:w-14 lg:h-auto object-contain mx-auto sm:mx-0"
                                        alt={product.name}
                                    />
                                </div>
                                <div className="flex items-center gap-2 sm:col-span-1">
                                    <span className="text-gray-500 sm:hidden">Quantity:</span>
                                    <input
                                        type="number"
                                        min="1"
                                        value={quantities[product._id]}
                                        onChange={(e) => handleQuantityChange(product._id, e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-20 p-1 lg:p-2.5"
                                    />
                                </div>
                                <div className="text-gray-500 line-through sm:col-span-1">
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>} {product.price.toLocaleString()}
                                </div>
                                <div className="sm:col-span-1">
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>}{" "}
                                    {calculateDiscountedPrice(product.type, product.price, quantities[product._id]).toLocaleString()} each
                                </div>

                                <div className="sm:col-span-1">
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>}{" "}
                                    { (quantities[product._id] * calculateDiscountedPrice(product.type, product.price, quantities[product._id])).toLocaleString()} 
                                </div>

                                <div className="sm:col-span-1">
                                    <LocalOfferIcon sx={{fontSize:"16px"}} />{" "}
                                    Save{" "}
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>}{" "}
                                    {( quantities[product._id] * 
                                        (product.price - calculateDiscountedPrice(product.type, product.price, quantities[product._id])) 
                                    ).toLocaleString()}
                                </div>
                                <div className="sm:col-span-1">
                                    <ClearIcon onClick={() => handleRemoveFromCart(product)} />
                                </div>
                            </div>
                        ))}

                        {products.filter(product => product.type === "semitrailer").map(product => (
                            <div
                                className="flex flex-col sm:grid sm:grid-cols-8 gap-4 items-center p-2 border-b border-gray-200 last:border-b-0"
                                key={product._id}
                            >
                                <div className="font-bold sm:col-span-1">{product.name}</div>
                                <div className="sm:col-span-1">
                                    <img
                                        src={product.type === "tyre" ? product.image : product.images[0]}
                                        className="w-20 h-20 lg:w-20 lg:h-auto object-contain mx-auto sm:mx-0"
                                        alt={product.name}
                                    />
                                </div>
                                <div className="flex items-center gap-2 sm:col-span-1">
                                    <span className="text-gray-500 sm:hidden">Quantity:</span>
                                    <input
                                        type="number"
                                        min="1"
                                        value={quantities[product._id]}
                                        onChange={(e) => handleQuantityChange(product._id, e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-20 p-1 lg:p-2.5"
                                    />
                                </div>
                                <div className="text-gray-500 line-through sm:col-span-1">
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>} {product.price.toLocaleString()}
                                </div>
                                <div className="sm:col-span-1">
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>}{" "}
                                    {calculateDiscountedPrice(product.type, product.price, quantities[product._id]).toLocaleString()} each
                                </div>

                                <div className="sm:col-span-1">
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>}{" "}
                                    { (quantities[product._id] * calculateDiscountedPrice(product.type, product.price, quantities[product._id])).toLocaleString()} 
                                </div>

                                <div className="sm:col-span-1">
                                    <LocalOfferIcon sx={{fontSize:"16px"}} />{" "}
                                    Save{" "}
                                    {product.type === "tyre" ? <span>Ksh.</span> : <span>USD</span>}{" "}
                                    {( quantities[product._id] * 
                                        (product.price - calculateDiscountedPrice(product.type, product.price, quantities[product._id])) 
                                    ).toLocaleString()}
                                </div>
                                <div className="sm:col-span-1">
                                    <ClearIcon onClick={() => handleRemoveFromCart(product)} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* <div className="flex justify-center gap-4 p-10 lg:gap-10 mt-5 lg:mt-1">
                    <div className="text-bold">Total:</div>
                    <div className="text-bold">Ksh. {total.toLocaleString()}</div>
                </div> */}
                <div className="flex justify-center px-10 ml-5 lg:mb-8 mt-10">
                    {token !== null ? (
                        <Link to={'/order'}>
                            <div className="w-48 flex justify-center p-1 border-2 bg-black hover:bg-gray-600 rounded-lg text-white">
                                Place Order
                            </div>
                        </Link>
                    ) : (
                        <div>
                            <Link to={"/login"} className='mb-4 bg-orange-400 text-white p-2 text-sm rounded-md text-center'>You are not logged in! Login <span className="text-blue-800">here</span>  to complete order</Link>
                            {/* <Link to={'/login'}>
                                <div className="text-sm flex justify-center p-2 border-2 bg-red-500 hover:bg-gray-600 rounded-lg text-white">
                                    You need to login to Complete Order. Click Here to Login
                                </div>
                            </Link> */}
                        </div>
                    )}
                </div>
            </div>
            :
            <div className="flex justify-center mt-10 lg:mt-20 tracking-widest text-gray-600">
                Your <div className="mx-3"><ShoppingCartIcon /></div> is empty
            </div>
            }
        </div>
    );
};

export default Cart;
