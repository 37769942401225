const storedState = localStorage.getItem('state');

export const initialState = storedState ? JSON.parse(storedState) : {
    total: 0,
    products: []
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_STATE":
            return payload;

        case "ADD_TO_CART":
            // let addState = {
            //     ...state,
            //     products: payload.products
            // };
            // localStorage.setItem('state', JSON.stringify(addState));
            // return addState;
            const addedProducts = payload.products.map(product => {
                let discount = 0;

                if(product.type === "tyre") {
                    if (product.quantity >= 1 && product.quantity <= 20) {
                        discount = 500;
                    } else if (product.quantity >= 21 && product.quantity <= 40) {
                        discount = 1000;
                    } else if (product.quantity >= 41) {
                        discount = 2000;
                    }
                } else {
                    if (product.quantity >= 1 && product.quantity <= 10) {
                        discount = 1000;
                    } else if (product.quantity >= 11 && product.quantity <= 20) {
                        discount = 2000;
                    } else if (product.quantity >= 21) {
                        discount = 3000;
                    }
                }
                const discountedPrice = product.price - discount;
                const productTotal = discountedPrice * product.quantity;

                return {
                    ...product,
                    discountedPrice,
                    total: productTotal
                };
            });
            
            const addTotalPrice = addedProducts.reduce((acc, item) => acc + item.total, 0);

            const addState = {
                ...state,
                products: addedProducts,
                total: addTotalPrice
            };

            localStorage.setItem('state', JSON.stringify(addState));
            return addState;

        case "REMOVE_FROM_CART":
            let newState = {
                ...state,
                products: payload.products
            };
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;

        case "UPDATE_TOTAL":
            let updateState = {
                ...state,
                total: payload.total
            };
            localStorage.setItem('state', JSON.stringify(updateState));
            return updateState;

        case "CLEAR_STATE":
            let clear = {
                total: 0,
                products: []
            };
            localStorage.removeItem('state');
            return clear;

        case "UPDATE_QUANTITY":
            const updatedProducts = payload.products.map(product => {
                let discount = 0;

                if(product.type == "tyre"){
                    if (product.quantity >= 1 && product.quantity <= 20) {
                        discount = 500;
                    } else if (product.quantity >= 21 && product.quantity <= 40) {
                        discount = 1000;
                    } else if (product.quantity >= 41) {
                        discount = 2000;
                    }
                }else{

                    if (product.quantity >= 1 && product.quantity <= 10) {
                        discount = 1000;
                    } else if (product.quantity >= 11 && product.quantity <= 20) {
                        discount = 2000;
                    } else if (product.quantity >= 21) {
                        discount = 3000;
                    }
                }

                // Calculate discounted price per tyre
                const discountedPrice = product.price - discount;
                const productTotal = discountedPrice * product.quantity;

                return {
                    ...product,
                    discountedPrice,
                    total: productTotal
                };
            });

            // Calculate the total cart price after discount
            const totalPrice = updatedProducts.reduce((acc, item) => acc + item.total, 0);

            const updatedQuantityState = {
                ...state,
                products: updatedProducts,
                total: totalPrice
            };
            
            localStorage.setItem('state', JSON.stringify(updatedQuantityState));
            return updatedQuantityState;

        default:
            throw new Error(`No case for type ${type} found in reducer`);
    }
};

export default reducer;
