const semiTrailerData = [
    {
        _id: "trailer001",
        type: "semitrailer",
        images: [
            require('../assets/images/semitrailers/Picture1.jpg'),
            require('../assets/images/semitrailers/Picture5.png'),
        ],
        name: "Axle FlatBed Trailer",
        price: 23000.00,
        description: [
            { key: "Model No", value: "TAZ9480" },
            { key: "External Dimensions", value: "12500×2580×1540mm" },
            { key: "Wheelbase", value: "7080+1310+1310mm" },
            { key: "Maximum Loading Capacity", value: "35000Kg" },
            { key: "Traction Pin", value: "90# (3.5 inch)" },
            { key: "Support Arms", value: "28 ton-Support arms" },
            { key: "Axles", value: "13000kg grade; CONMET maintenance-free wheel end system" },
            { key: "Suspension", value: "Reinforced heavy-duty three-axle mechanical suspension" },
            { key: "Tyres", value: "315/80R22.5, 12 pcs in total" },
            { key: "Brake System", value: "WABCO relay valve and enlarged iron air reservoir" },
            { key: "Locks", value: "12 container locks" },
            { key: "Toolbox", value: "1 standard toolbox" },
            { key: "Spare Wheel Carrier", value: "Framed spare wheel carrier as standard (for 2 spare wheels)" },
            { key: "Color", value: "White" }
        ]
    },
    {
        _id: "trailer002",
        type: "semitrailer",
        images: [
            require('../assets/images/semitrailers/Picture6.jpg'),
            require('../assets/images/semitrailers/Picture2.jpg')
        ],
        name: "Axle Half Body Trailer",
        price: 24000.00,
        description: [
            { key: "Model No", value: "TAZ9480" },
            { key: "External Dimensions", value: "12500×2580×2740mm" },
            { key: "Wheelbase", value: "7080+1310+1310mm" },
            { key: "Maximum Load Capacity", value: "35000Kg" },
            { key: "Cabin (Height of Rails)", value: "800mm" },
            { key: "Traction Pin", value: "90# (3.5 inch)" },
            { key: "Support Arms", value: "28-ton outriggers" },
            { key: "Axle", value: "13000kg grade; CONMET maintenance-free wheel end system" },
            { key: "Suspension", value: "Reinforced heavy-duty three-axle mechanical suspension" },
            { key: "Tires", value: "315/80R22.5, 12 pcs in total" },
            { key: "Pneumatic Brake", value: "WABCO relay valve and enlarged iron air reservoir" },
            { key: "Locks", value: "12 container locks" },
            { key: "Toolbox", value: "1 standard toolbox" },
            { key: "Spare Tire Rack", value: "Framed spare wheel carrier as standard (for 2 spare wheels)" },
            { key: "Color", value: "White" }
        ]
    },
    {
        _id: "trailer003",
        type: "semitrailer",
        images: [
            require('../assets/images/semitrailers/Picture3.jpg'),
            require('../assets/images/semitrailers/Picture7.jpg')
        ],
        name: "Axle Container Frame Trailer",
        price: 23000.00,
        description: [
            { key: "Model No", value: "TAZ9480" },
            { key: "External Dimensions", value: "12500×2580×2740mm" },
            { key: "Wheelbase", value: "7080+1310+1310mm" },
            { key: "Maximum Load Capacity", value: "35000Kg" },
            { key: "Cabin (Height of Rails)", value: "800mm" },
            { key: "Traction Pin", value: "90# (3.5 inch)" },
            { key: "Support Arms", value: "28-ton outriggers" },
            { key: "Axle", value: "13000kg grade; CONMET maintenance-free wheel end system" },
            { key: "Suspension", value: "Reinforced heavy-duty three-axle mechanical suspension" },
            { key: "Tires", value: "315/80R22.5, 12 pcs in total" },
            { key: "Pneumatic Brake", value: "WABCO relay valve and enlarged iron air reservoir" },
            { key: "Locks", value: "12 container locks" },
            { key: "Toolbox", value: "1 standard toolbox" },
            { key: "Spare Tire Rack", value: "Framed spare wheel carrier as standard (for 2 spare wheels)" },
            { key: "Color", value: "White" }
          ]
    },
    // {
    //     images: [
    //         require('../assets/images/semitrailers/Picture4.jpg'),
    //         require('../assets/images/semitrailers/Picture8.jpg')
    //     ],
    //     name: "Axle Tipping Trailer",
    //     price: 82500.00,
    //     description: [
    //         { key: "Load Capacity", value: "30 Tons" },
    //         { key: "External Dimensions", value: "8800×2800×3700mm" },
    //         { key: "Internal Dimensions", value: "8000×2600×1700mm" },
    //         { key: "Body", value: "Material T700, bottom 3mm, side 3mm" },
    //         { key: "Traction Pin", value: "90#" },
    //         { key: "Support Legs", value: "28-ton outriggers" },
    //         { key: "Axles", value: "13000kg × 3" },
    //         { key: "Suspension", value: "Reinforced heavy-duty 3-axle mechanical suspension" },
    //         { key: "Tires", value: "315/80R22.5, 12 pcs" },
    //         { key: "Toolbox", value: "1 pc standard" },
    //         { key: "Spare Tire Carriers", value: "Front panel with two carriers for two spare tires, with fixed pressure plate" },
    //         { key: "Color", value: "White" },
    //         { key: "Hydraulic Cylinder", value: "PERMCO 196 cylinder" }
    //     ]
    // }
];

export default semiTrailerData;
