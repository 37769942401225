import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderModal = ({ isOpen, onClose, data }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [delivery, setDelivery] = useState(false);
  const [pickup, setPickup] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [deliveryLocation, setDeliveryLocation] = useState(null);
  const [branch, setBranch] = useState('Nairobi Branch');
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleDeliveryChange = () => {
    setDelivery(true);
    setPickup(false);
  };

  const handlePickupChange = () => {
    setPickup(true);
    setDelivery(false);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if(firstName == null || lastName == null || email == null || phoneNumber == null){
      toast("All Fields Must be filled",{
        type:"error",
        autoClose: 500
      })
      setLoading(false);
      return;
    }

    let htmlTemplate = null;

    if(pickup){
      htmlTemplate = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>New Order Notification</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #fff;
                      margin: 0;
                      padding: 20px;
                      color: #333;
                  }
                  .container {
                      max-width: 600px;
                      margin: 0 auto;
                      border: 1px solid #333; /* Light orange border */
                      border-radius: 5px;
                      overflow: hidden;
                      background-color: #fff;
                  }
                  .header {
                      background-color: #333;
                      color: #fff;
                      padding: 10px;
                      text-align: center;
                  }
                  .content {
                      padding: 20px;
                  }
                  .footer {
                      background-color: #333; /* Black background */
                      color: #fff;
                      text-align: center;
                      padding: 10px;
                  }
                  .label {
                      font-weight: bold;
                      color: #333;
                  }
                  span {
                      maring-right: 5px;
                  }
              </style>
          </head>
          <body>
              <div class="container">
                  <div class="header">
                      <h1>New Order Notification From Ecommerce Site</h1>
                  </div>
                  <div class="content">
                      <p><span class="label">Full Name:</span>${firstName} ${lastName}</p>
                      <p><span class="label">Email:</span> ${email}</p>
                      <p><span class="label">Phone Number:</span>${phoneNumber}</p>
                      <p><span class="label">Product:</span>${data.name}</p>
                      <p><span class="label">Quantity:</span>${quantity} Unit(s)</p>
                      <p><span class="label">Pickup Location:</span>${branch}</p>
                  </div>
                  <div class="footer">
                      <p>Reach out to the customer!</p>
                  </div>
              </div>
          </body>
          </html>
      `
    }else{
      htmlTemplate =  `
          <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>New Order Notification</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #fff;
                      margin: 0;
                      padding: 20px;
                      color: #333;
                  }
                  .container {
                      max-width: 600px;
                      margin: 0 auto;
                      border: 1px solid #333;
                      border-radius: 5px;
                      overflow: hidden;
                      background-color: #fff;
                  }
                  .header {
                      background-color: #333;
                      color: #fff;
                      padding: 10px;
                      text-align: center;
                  }
                  .content {
                      padding: 20px;
                  }
                  .footer {
                      background-color: #333; /* Black background */
                      color: #fff;
                      text-align: center;
                      padding: 10px;
                  }
                  .label {
                      font-weight: bold;
                      color: #333; 
                  }
                  span {
                      margin-right: 5px;
                  }
              </style>
          </head>
          <body>
              <div class="container">
                  <div class="header">
                      <h1>New Order Notification From Ecommerce Site</h1>
                  </div>
                  <div class="content">
                      <p><span class="label">Full Name:</span>${firstName} ${lastName}</p>
                      <p><span class="label">Email:</span> ${email}</p>
                      <p><span class="label">Phone Number:</span>${phoneNumber}</p>
                      <p><span class="label">Product:</span>${data.name}</p>
                      <p><span class="label">Quantity:</span>${quantity} Unit(s)</p>
                      <p><span class="label">Delivery Location:</span>${deliveryLocation}</p>
                  </div>
                  <div class="footer">
                      <p>Reach out to the customer!</p>
                  </div>
              </div>
          </body>
          </html>
      `
    }

    let sentData = { 
      subject: "New Order From Navi Ecommerce Website",
      htmlTemplate
     };

     let larkMessage = '';

     if(pickup){
      larkMessage = 
      `🚨 ${sentData.subject.toUpperCase()}\nCustomer Name: ${firstName} ${lastName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nProduct: ${data.name}\nQuantity: ${quantity}\nPickup branch: ${branch}
      `;
     }else{
      larkMessage =
      `🚨 ${sentData.subject.toUpperCase()}\nCustomer Name: ${firstName} ${lastName}\nPhone Number: ${phoneNumber}\nEmail: ${email}\nProduct: ${data.name}\nQuantity: ${quantity}\nDelivery Location: ${deliveryLocation}
      `;
     }

     sentData = { ...sentData, larkMessage }

    fetch(`${process.env.REACT_APP_API_URL}/send_email`,{
      method: 'POST',
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(sentData)
    })
    .then(()=>{
      toast("Order Succesfully Placed. You Will be contacted as soon as possible",{
        type:"success",
        autoClose: 1500
      });
      setLoading(false);
      setFirstName(null);
      setLastName(null);
      setPhoneNumber(null);
      setEmail(null);
      setPickup(true);
      setQuantity(1);
      setDelivery(false);
      setDeliveryLocation(null);
      setBranch(null);

      setTimeout(()=>{
        onClose();
      },2000)
      
    })
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={onClose}></div>

      {/* Modal content */}
      <div className="relative bg-white w-5/6 lg:w-1/2 p-6 rounded-lg shadow-lg z-20 text-sm">
        <form onSubmit={handleSubmit}>
          <div className='mb-5 text-center text-2xl uppercase font-montserrat font-bold tracking-wide'>Place An Order</div>
          <div className='flex w-full gap-4'>
            <div className='w-1/2'>
              <input
                type="text"
                onChange={e => setFirstName(e.target.value)}
                className='border border-gray-300 p-2 w-full rounded-lg'
                placeholder="First Name"
                required
              />
            </div>
            <div className='w-1/2'>
              <input
                type="text"
                onChange={e => setLastName(e.target.value)}
                className='border border-gray-300 p-2 w-full rounded-lg'
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          <div>
            <input
              type="email"
              onChange={e => setEmail(e.target.value)}
              className='border border-gray-300 p-2 w-full rounded-lg mt-5'
              placeholder='johndoe@gmail.com'
              required
            />
          </div>
          <div>
            <input
              type="text"
              onChange={e => setPhoneNumber(e.target.value)}
              className='border border-gray-300 p-2 w-full rounded-lg mt-5'
              placeholder='Phone Number'
              required
            />
          </div>

          <div className='mt-5'>
            <div>Do you want the products to be delivered to your location?</div>
            <div>
              <input
                type="radio"
                id="yes"
                name="delivery"
                checked={delivery}
                onChange={handleDeliveryChange}
              />
              <label htmlFor="yes" className='ml-2'>Yes</label>
            </div>
            {delivery && (
              <input
                type="text"
                placeholder='Enter Delivery Location'
                className='p-2 border border-gray-300 rounded-lg my-2 w-full'
                value={deliveryLocation}
                onChange={e => setDeliveryLocation(e.target.value)}
              />
            )}
            <div>
              <input
                type="radio"
                id="no"
                name="delivery"
                checked={pickup}
                onChange={handlePickupChange}
              />
              <label htmlFor="no" className='ml-2'>No, I will pick them up at the store.</label>
            </div>
            {pickup && (
              <div>
                <div className='mt-2 font-bold mb-1 ml-1'>Select branch:</div>
                <select className='p-2 rounded-lg border border-gray-300' onChange={e => {
                  setBranch(e.target.value)
                }}>
                  <option value={"Nairobi Branch"}>Nairobi Branch</option>
                  <option value={"Kisumu Branch"}>Kisumu Branch</option>
                  <option value={"Eldoret Branch"}>Eldoret Branch</option>
                  <option value={"Mombasa Branch"}>Mombasa Branch</option>
                </select>
              </div>
            )}
          </div>

          <div className='bg-gray-100 border border-gray-300 p-2 mt-5 mb-2 rounded-lg'>
            <div className='flex my-3 gap-2 items-center'>
              <div>Product: </div>
              <div className='text-base lg:text-lg font-bold'>{data.name}</div>
            </div>
            <div className='flex gap-1 lg:gap-2 items-center my-3'>
              <div>Set Quantity:</div>
              <div>
                <input
                  type="number"
                  value={quantity}
                  className='border border-gray-200 px-2 py-1 rounded-lg'
                  onChange={e => setQuantity(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 text-center font-mono bg-blue-200 p-2 rounded-lg">
              * Thank you! We will get back to you as soon as you submit this order form.
          </div>

          <div className='flex justify-center gap-3'>
            <button
              type="button"
              onClick={onClose}
              className='border border-primary-100 text-primary-100 hover:bg-orange-600 hover:text-white py-2 px-4 rounded-lg w-1/2 lg:w-1/4 mt-5'
            >
              Cancel
            </button>
            {
              loading ? 
              <button
              className='bg-gray-400 py-2 px-4 rounded-lg text-black w-1/2 lg:w-1/4 mt-5'
              >Loading ... </button>
              :
              <input
                onClick={(e)=>{
                  handleSubmit(e);
                }}
                type="submit"
                value="Submit Order"
                className='bg-primary-100 hover:bg-orange-600 py-2 px-4 rounded-lg text-white w-1/2 lg:w-1/4 mt-5'
              />
            }
            
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderModal;
