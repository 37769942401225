import React from 'react'
import { Link } from 'react-router-dom'

function SalesBanner() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className='my-5 p-5 font-montserrat' style={{backgroundColor: "#f7f7f7"}}>
      <div className='mx-auto rounded-lg w-full lg:w-3/4 bg-gradient-to-r from-black via-zinc-700 to-zinc-500 flex p-5 my-5 lg:px-10'>

      <div className='w-2/3 lg:w-3/4 my-auto'>
        <div className='text-white text-lg lg:text-2xl font-semibold font-montserrat mb-3 lg:mb-5'>We offer the best tyre prices on the market</div>
        <Link to={"/contact_us"} onClick={()=>{
          scrollToTop();
        }} className='bg-primary-100 text-white rounded-3xl px-3 py-1 text-xs lg:text-base'>Contact Us Now</Link>
      </div>
      <div className='w-1/3 lg:w-1/4'>

      <img src={require('../assets/images/suv-car-tyre-500x500-removebg-preview.png')} className='w-96 lg:w-64 object-contain' />

      </div>

      </div>
    </div>
  )
}

export default SalesBanner
