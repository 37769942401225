import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../context/AuthContext';

function Register() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [loading, setLoading] = useState(false);

    const {  } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleSubmit = (e) =>{ 
        setLoading(true);
        e.preventDefault();

        if(email == null || password == null || firstName == null || lastName == null || phoneNumber == null || companyName == null){
            toast.error("All Fields Must be Filled");
            setLoading(false);
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/register_user`,{
            method: 'POST',
            headers : {
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                email, password, first_name: firstName, second_name:lastName, phoneNumber, companyName
            })
        })
        .then(data =>{
            if(data.ok){
                toast.success("Success. Proceed to Login");
                setTimeout(()=>{
                    setLoading(false);
                    navigate('/login');
                },700)
            }else{
                toast.error("Failed to Register. Email/ Phone Number Has Been Used");
                setLoading(false);
            }
        })
        .catch((err)=>{
            toast.error("Failed to Login:" + err)
            setLoading(false);
        })
    }
  return (
    <div className='p-10 px-5 lg:px-24 gap-52 font-montserrat mt-16  min-h-screen' style={{ backgroundColor: "#f3f3f3"}}>
        <ToastContainer />
      <div className='flex w-11/12 lg:w-1/3 mx-auto bg-white rounded-lg mt-20 lg:mt-20'>
            <form className='w-full p-5 text-xs'>
                <div className='text-center mb-5 font-bold tracking-wider text-lg'>Register</div>
                <div className='w-full block lg:flex gap-2'>
                    <div className='w-full lg:w-1/2  mb-5 lg:mb-0'>
                        <input type='text' placeholder="First Name" onChange={e => setFirstName(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <input type='text' placeholder="Last Name" onChange={e => setLastName(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                    </div>
                </div>
                
                <div className='w-full mt-5'>
                    <input type='text' placeholder="Company Name" onChange={e => setCompanyName(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                </div>
        
                <div className='w-full mt-5'>
                    <input type='text' placeholder="Email" onChange={e => setEmail(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                </div>

                <div className='w-full mt-5'>
                    <input type='text' placeholder="Phone Number" onChange={e => setPhoneNumber(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                </div>

                <div className='w-full mt-5'>
                    <input type='password' placeholder="Password" onChange={e => setPassword(e.target.value)} className='p-2 w-full border border-gray-400 rounded-lg' />
                </div>

                { !loading && <div className='flex justify-center mb-3'>
                    <button onClick={e =>{
                        handleSubmit(e);
                    }} className='w-full text-center bg-primary-100 mt-5 p-2 text-white rounded-lg'>Register</button>
                </div> }
                {
                    loading && <div className='flex justify-center mb-3'>
                    <button onClick={e =>{
                        e.preventDefault();
                    }} className='w-full text-center bg-zinc-500 mt-5 p-2 text-white rounded-lg'>Loading ....</button>
                </div>
                }

                <Link to={"/login"} className='cursor-pointer'>I have an account? <span className='text-blue-700'>Login</span></Link>
            </form>
      </div>
    </div>
  )
}

export default Register
